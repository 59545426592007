import React, { useRef, useEffect } from "react";
import { Chart } from "chart.js/auto";

const ChartComponent = ({ data }) => {
  const chartRef = useRef(null);

  const labelsKey = Object.keys(data[0]).find((key) =>
    key.toLowerCase().includes("days")
  );
  const datasetKeys = Object.keys(data[0]).filter((key) => key !== labelsKey);

  useEffect(() => {
    const ctx = chartRef.current.getContext("2d");

    const chartInstance = new Chart(ctx, {
      type: "line",
      data: {
        labels: data.map((item) => item[labelsKey]),
        datasets: datasetKeys.map((key, index) => ({
          label: key.replace(/_/g, " "),
          data: data.map((item) => item[key]),
          borderColor: index === 0 ? "blue" : "orange",
          fill: false,
          tension: 0.1,
        })),
      },
      options: {
        responsive: true,
        plugins: {
          title: {
            display: true,
            text: `Comparison of ${datasetKeys.join(" vs ")}`.replace(
              /_/g,
              " "
            ),
          },
        },
        scales: {
          y: {
            beginAtZero: true,
            suggestedMax:
              Math.max(
                ...data.flatMap((item) => datasetKeys.map((key) => item[key]))
              ) + 100,
          },
        },
      },
    });

    return () => {
      chartInstance.destroy();
    };
  }, [data, datasetKeys, labelsKey]);

  return <canvas style={{ height: "20vh" }} ref={chartRef}></canvas>;
};

export default ChartComponent;

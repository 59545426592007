import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import MainSelectedMaterialsTable from "./MainSelectedMaterials.table";
import { Divider, Grid } from "@mui/material";
import GroupingTable from "./GroupingTable";
import { DrawingBomContext } from "../../DrawingBom.context";
import GenerateTable from "./GenerateTable";
import { generatingProcuredData } from "./GenerateTable/utils";
import MrqForm from "./MrqForm";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95%",
  maxHeight: "95%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "auto",
};

export default function ConsolidateModal() {
  const {
    openConsolidateModal,
    handleCloseConsolidateModal,
    consolidatedMaterials,
    generatingData,
    setGeneratingData,
    onClickAddMrq,
    isMrqFormVisible,
    onCreateMrq,
  } = React.useContext(DrawingBomContext);

  const onGenerate = () => {
    const generatedData = generatingProcuredData(generatingData);
    setGeneratingData(generatedData);
  };

  return (
    <div>
      <Modal
        open={openConsolidateModal}
        onClose={handleCloseConsolidateModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="h6" sx={{ mb: 1 }}>
            Selected Materials: ({consolidatedMaterials.length})
          </Typography>

          <MainSelectedMaterialsTable
            consolidatedMaterials={consolidatedMaterials}
          />
          <Divider sx={{ my: 2 }} />

          <Grid container>
            <Grid item xs={5}>
              <Typography variant="h6" sx={{ mb: 1 }}>
                Consolidated Materials:
              </Typography>
            </Grid>
            <Grid item xs={7} sx={{ pl: 2, pb: 1 }}>
              <Button variant="outlined" onClick={onGenerate}>
                Generate
              </Button>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={5} sx={{ pr: 2 }}>
              <GroupingTable />
            </Grid>
            <Grid item xs={7} sx={{ pl: 2 }}>
              <GenerateTable
                generatingData={generatingData}
                setGeneratingData={setGeneratingData}
              />
            </Grid>
          </Grid>
          {isMrqFormVisible && (
            <Box>
              <Divider sx={{ m: 2 }} />
              <MrqForm />
            </Box>
          )}
          <Box>
            <Button
              variant="contained"
              sx={{ mt: 2, mx: 1, float: "right" }}
              onClick={handleCloseConsolidateModal}
            >
              Close
            </Button>

            {isMrqFormVisible ? (
              <Button
                variant="contained"
                sx={{ mt: 2, mx: 1, float: "right" }}
                onClick={onCreateMrq}
              >
                Create Mrq
              </Button>
            ) : (
              <Button
                variant="contained"
                sx={{ mt: 2, mx: 1, float: "right" }}
                onClick={onClickAddMrq}
              >
                Add to Mrq
              </Button>
            )}
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

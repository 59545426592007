import { MenuItem } from "@mui/material";
import {
  materialsOptions,
  plateSizeOptions,
  requirementsOptions,
} from "./GenerateTable/utils";

export const groupingTableCols = [
  { accessorKey: "item", header: "Item", size: 50 },
  { accessorKey: "markNo", header: "Mark", size: 50 },
  {
    accessorKey: "quantity",
    header: "Qty",
    enableEditing: false,
    size: 50,
  },
  {
    accessorKey: "totalWtInKgs",
    header: "Total Wt",
    size: 50,
  },
  { accessorKey: "materialNo", header: "Material", size: 70 },
  { accessorKey: "materialCode", header: "Material Code", size: 70 },
];

const mcsDataCols = [
  {
    accessorKey: "mrqNumber",
    header: "Mrq #",
    enableEditing: false,
    size: 70,
  },
  {
    accessorKey: "poNumber",
    header: "PO #",
    enableEditing: false,
    size: 70,
  },
  {
    accessorKey: "mrvNumber",
    header: "MRV #",
    enableEditing: false,
    size: 70,
  },
  {
    accessorKey: "srvNumber",
    header: "SRV #",
    enableEditing: false,
    size: 70,
  },
  {
    accessorKey: "heatNumber",
    header: "Heat #",
    enableEditing: false,
    size: 70,
  },

  {
    accessorKey: "actions",
    header: "Actions",
    enableEditing: false,
    size: 30,
  },
];
export const bulkedMaterialsCols = [
  { accessorKey: "item", header: "Item", size: 50 },
  { accessorKey: "markNo", header: "Mark", size: 50 },
  {
    accessorKey: "quantity",
    header: "Qty",
    enableEditing: false,
    size: 50,
  },
  { accessorKey: "totalWtInKgs", header: "Total Wt", size: 50 },
  { accessorKey: "materialNo", header: "Material", size: 70 },
  { accessorKey: "materialCode", header: "Material Code", size: 70 },
  { accessorKey: "description", header: "Description", size: 150 },
  ...mcsDataCols,
];

export const taggedMaterialsCols = [
  // {
  //   header: "PnID",
  //   accessorKey: "taggedMaterial.pnid",
  //   enableEditing: false,
  //   size: 30,
  // },
  {
    header: "Tag Number",
    accessorKey: "taggedMaterial.tagNumber",
    enableEditing: false,
    size: 50,
  },
  {
    header: "Description",
    accessorKey: "description",
    enableEditing: false,
    size: 200,
  },
  // {
  //   header: "Component",
  //   accessorKey: "taggedMaterial.component",
  //   enableEditing: false,
  //   size: 50,
  // },
  // {
  //   header: "Size",
  //   accessorKey: "taggedMaterial.size",
  //   enableEditing: false,
  //   size: 50,
  // },
  // {
  //   header: "Line Number",
  //   accessorKey: "taggedMaterial.lineNumber",
  //   enableEditing: false,
  //   size: 50,
  // },
  // {
  //   header: "Category",
  //   accessorKey: "taggedMaterial.category",
  //   enableEditing: false,
  //   size: 70,
  // },
  // {
  //   header: "MRF",
  //   accessorKey: "taggedMaterial.mrf",
  //   enableEditing: false,
  //   size: 40,
  // },
  // {
  //   header: "MRF #",
  //   accessorKey: "taggedMaterial.mrfNumber",
  //   enableEditing: false,
  //   size: 40,
  // },
  // {
  //   header: "Cost $",
  //   accessorKey: "taggedMaterial.cost",
  //   enableEditing: false,
  //   size: 40,
  // },
  // {
  //   header: "Remarks",
  //   accessorKey: "taggedMaterial.remarks",
  //   enableEditing: false,
  //   size: 40,
  // },
  ...mcsDataCols,
];

export const GenerateTableCols = [
  {
    accessorKey: "size",
    header: "Plate Size",
    size: 20,
    muiEditTextFieldProps: ({ row }) => ({
      children: plateSizeOptions
        .find((opt) => opt.value === "16")
        .size.map((func) => (
          <MenuItem key={func} value={func}>
            {func}
          </MenuItem>
        )),
      select: true,
    }),
  },
  {
    accessorKey: "thickness",
    header: "Thk (mm)",
    // header: (
    //   <div>
    //     <p>Thk</p>
    //     <p>(mm)</p>
    //   </div>
    // ),
    size: 10,
  },
  {
    accessorKey: "materialNo",
    header: "Material",
    size: 50,
    muiEditTextFieldProps: () => ({
      children: materialsOptions
        .map((opt) => opt.material)
        .map((func) => (
          <MenuItem key={func} value={func}>
            {func}
          </MenuItem>
        )),
      select: true,
    }),
  },
  {
    accessorKey: "requirements",
    header: "Requirements",
    size: 30,
    muiEditTextFieldProps: () => ({
      children: requirementsOptions
        .map((opt) => opt.req)
        .map((func) => (
          <MenuItem key={func} value={func}>
            {func}
          </MenuItem>
        )),
      select: true,
    }),
  },
  {
    accessorKey: "quantity",
    header: "Qty",
    size: 50,
  },
  {
    accessorKey: "description",
    header: "Description",
    size: 70,
    enableEditing: false,
  },
  {
    accessorKey: "materialCode",
    header: "Material Code",
    size: 40,
    enableEditing: false,
  },
  {
    accessorKey: "totalWeight",
    header: "Total Weight",
    size: 40,
    enableEditing: false,
  },
  { accessorKey: "diff", header: "Diff", size: 50, enableEditing: false },
];

import React, { useEffect, useState } from 'react';
import { createTask, deleteTask, getAllUsers, getTasks, updateTask } from './api';
import { initialDialogState, taskListColumns } from './utils';
// import CommentsDialog from './components/CommentsDialog';
import TimesheetDialog from '../../../../../../components/Dialogs/TimesheetDialog';
import { useParams } from 'react-router-dom';
import EditableTable from '../../../../../../components/Tables/EditableTable';
import { createTimesheet, deleteTimesheet, getTimesheets, updateTimesheet } from './timesheetApi';
// import { createComment, getComments } from './components/CommentsDialog/api';
import CommentsDialog from '../../../../../../components/Dialogs/CommentsDialog';
import { createComment, getComments } from './commentsApi';
// import { getUser } from '../../../api';

const initialData = {
  id: "",
  task: "",
  priority: "",
  startDate: "",
  endDate: "",
  status: "",
  hours: 0,
  progress: 0,
  users: [],
};

const PunchList = () => {
  const { equipId } = useParams();
  const [dialogState, setDialogState] = useState(initialDialogState);
  const [selectedTask, setSelectedTask] = useState();
  const [userData, setUserData] = useState();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const res = await getAllUsers();
        setUserData(res);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
    fetchUserData();
  }, []);

  console.log({ userData })

  const handleButtonClick = (type, row) => {
    setSelectedTask(row.original);
    if (type === 'comments') {
      setDialogState(prev => ({ ...prev, comments: true }));
    } else if (type === 'attachment') {
      setDialogState(prev => ({ ...prev, attachment: true }));
    } else if (type === 'timesheet') {
      setDialogState(prev => ({ ...prev, timesheet: true }));
    }
  };

  const handleDialogClose = () => {
    setDialogState(initialDialogState);
  };

  return (
    <div>
      {/* {userData ? ( */}

      <EditableTable
        name="Task"
        displayMode="row"
        columns={taskListColumns({
          project_members: userData || [],
          handleButtonClick,
          currentUser: "currentUser",
        })}
        enableEditing={true}
        enableCreate={true}
        initialData={initialData}
        api={{
          getItems: getTasks,
          createItem: createTask,
          updateItem: updateTask,
          deleteItem: deleteTask,
        }}
        parentId={equipId}
      />
      {/* // )
        //   :
        //   <>
        //     Loading user data...
        //   </>
        // } */}
      {selectedTask && (
        <>
          <CommentsDialog
            open={dialogState.comments}
            handleClose={handleDialogClose}
            id={selectedTask?.id}
            api={{
              getComments: getComments,
              createComment: createComment
            }}
          />
          <TimesheetDialog
            open={dialogState.timesheet}
            handleClose={handleDialogClose}
            parentId={selectedTask?.id}
            parentName={selectedTask?.task}
            api={{
              getItems: getTimesheets,
              createItem: createTimesheet,
              updateItem: updateTimesheet,
              deleteItem: deleteTimesheet,
            }}
          />
        </>
      )}
    </div>
  );
};

export default PunchList;

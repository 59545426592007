import { Paper } from "@mui/material";
import React from "react";
import NavTabs from "../../../../../components/Tabs/NavTabs";

import MasterMaterialTab from "../../../SCMPage/WarehouseTab/MasterMaterialTab";
import DrawingBomTab from "./DrawingBom";
import PoReconcile from "./PoReconcile";
import SPIRTab from "./SPIRTab";
import MRQTab from "./MRQTab";
import BidTab from "./BidTab";
import { MCSContext } from "./MCS.context";

export default function Main() {
  const { currentTab, setCurrentTab } = React.useContext(MCSContext);

  const tabs = [
    {
      label: "Drawing BOM",
      panel: <DrawingBomTab />,
    },
    // {
    //   label: "BOM status",
    //   panel: <DrawingRegister />,
    // },
    {
      label: "MRQ",
      panel: <MRQTab />,
    },
    {
      label: "Bid Evaluation",
      panel: <BidTab />,
    },
    {
      label: "Materials Record",
      panel: <MasterMaterialTab />,
    },
    {
      label: "Reconcile",
      panel: <PoReconcile />,
    },
    {
      label: "SPIR",
      panel: <SPIRTab />,
    },
  ];

  return (
    <Paper sx={{ width: "100%", display: "flex" }}>
      <NavTabs
        tabs={tabs}
        // tabProps={verticalTabStyle}
        customCurrentTab={currentTab}
        setCustomCurrentTab={setCurrentTab}
        navLabel="mcs-tab"
        // vertical={true}
      />
    </Paper>
  );
}

import Main2 from "../../pages/client/AllEquipments/PressureVessel";
import ManageStaff from "../../pages/admin/ManageStaff";

//--------Staff-------
import StaffLogin from "../../pages/staff/auth/StaffLogin";
import StaffRegister from "../../pages/admin/StaffRegister";
import StaffRegisterPassword from "../../pages/staff/auth/StaffRegisterPassword";
import StaffResetPwdEmail from "../../pages/staff/auth/StaffResetPwdEmail";
import StaffResetPwd from "../../pages/staff/auth/StaffResetPwd";
import MyTasks from "../../pages/staff/MyTasks2";

//--------Guest-------
import GuestRegister from "../../pages/public/guest/GuestRegisterEmail";
import GuestDetails from "../../pages/public/guest/GuestRegisterDetails";
import GuestLogin from "../../pages/public/guest/GuesttLogin";
import GuestResetPwdEmail from "../../pages/public/guest/GuestResetPwdEmail";
import GuestResetPwd from "../../pages/public/guest/GuestResetPwd";

//---------project------------
import TankMain from "../../pages/client/AllEquipments/Tank/Main";
import MainProjects from "../../pages/client/Project/allProjectsPage";
import ProjectForm from "../../pages/client/Project/ProjectForm";
import EquipForm from "../../pages/client/Project/ProjectForm/EquipsForm";
import { AuthProtected } from "../AuthProtectedOutlet";
import { AdminProtected } from "../AdminProtectedOutlet";
// import AllTimesheets from "../../pages/admin/AllTimesheets";
import paths from "../../api/paths.api";
import ClientVendorsPage from "../../pages/client/ClientVendorsPage";

export const adminProtectedRoutes = [
  {
    path: "/main_pv/:name/:rev",
    element: <Main2 />,
  },
  // {
  //   path: "/register",
  //   element: <StaffRegister />,
  // },
  {
    path: "/allstaff",
    element: <ManageStaff />,
  },
  // {
  //   path: "/dev/register/:token",
  //   element: <StaffRegisterPassword />,
  // },
  // {
  //   path: "/dev/login",
  //   element: <StaffLogin />,
  // },
  // {
  //   path: "/dev/resetPassword",
  //   element: <StaffResetPwdEmail />,
  // },
  // {
  //   path: "/dev/resetPassword/:token",
  //   element: <StaffResetPwd />,
  // },
  // {
  //   path: "/guest/register",
  //   element: <GuestRegister />,
  // },
  // {
  //   path: "/guest/register/:token",
  //   element: <GuestDetails />,
  // },
  // {
  //   path: "/guest/login",
  //   element: <GuestLogin />,
  // },
  // {
  //   path: "/guest/resetPassword",
  //   element: <GuestResetPwdEmail />,
  // },
  // {
  //   path: "/guest/resetPassword/:token",
  //   element: <GuestResetPwd />,
  // },
  {
    path: "/projects",
    element: <MainProjects />,
  },
  {
    path: "/new-project",
    element: <ProjectForm />,
  },
  {
    path: "/project-equipments",
    element: <EquipForm />,
  },
  {
    path: paths.clientVendors,
    element: <ClientVendorsPage />,
  },
  {
    path: "",
    element: <AuthProtected />,
    children: [
      {
        path: "/mytasks",
        element: <MyTasks />,
      },
      {
        path: "",
        element: <AdminProtected />,
        children: [
          // {
          //   path: "/main_pv",
          //   element: <Main2 />,
          // },

          // {
          //   path: "/alltimesheets",
          //   element: <AllTimesheets />,
          // },
        ],
      },
    ],
  },
];

import { secondAxios } from "../../../../../api/axios";

export const getMrqs = async ({ projectId }) => {
  const response = await secondAxios.get(`/mrq/manager/${projectId}`);
  console.log({ mrqs: response });
  return response.data;
};

export const getMrqData = async (id) => {
  const response = await secondAxios.get(`/mrq/${id}`);
  return response.data;
};

export const getMrqPos = async (id) => {
  const response = await secondAxios.get(`po/mrq/${id}`);
  return response.data;
};

export const getAllVouchers = async (to) => {
  const response = await secondAxios.get(`/${to}`);
  return response.data;
};

export const addMaterialsTo = async ({ to, body }) => {
  const reqBody = {
    ...body,
    [`${to.toLowerCase()}Number`]: body.number,
    [`${to.toLowerCase()}Date`]: body.date,
  };

  console.log({ reqBody });
  const { data } = await secondAxios.post(to, reqBody);
  return data;
};

import * as React from "react";
import { MCSContextProvider } from "./MCS.context";

import Main from "./Main";

export default function MCS3({ project }) {
  return (
    <MCSContextProvider project={project}>
      <Main />
    </MCSContextProvider>
  );
}

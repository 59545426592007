import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import React from "react";
import { lightTable } from "../../../../../../../styles/muiProps";
import { Box } from "@mui/material";
import { designCols } from "./col";

export default function DesignTable({ drawings }) {
  const table = useMaterialReactTable({
    data: drawings,
    columns: designCols,
    ...lightTable,
    getRowId: (originalRow) => originalRow.id,

    enableStickyHeader: true,
    enableStickyFooter: true,
    muiTableBodyCellProps: {
      sx: {
        textAlign: "center",
        border: "1px solid rgba(81, 81, 81, .1)",
        padding: "10px",
      },
    },
    // muiTableFooterCellProps: {
    //   sx: {
    //     border: "0.5px solid rgba(81, 81, 81, .1)",
    //     // fontStyle: 'italic',
    //     fontWeight: "normal",
    //     padding: "10px",
    //     backgroundColor: "#f3f1fe",
    //   },
    // },
  });

  return (
    <Box sx={{ maxHeight: 400, backgroundColor: "#fff" }}>
      <MaterialReactTable table={table} />
    </Box>
  );
}

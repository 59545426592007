import React from "react";
import { Box, Grid } from "@mui/material";
import ChartComponent from "./CurveComponent";
import { ManhourData } from "../4d/dummyData";
import { CostData } from "../4d/dummyData";

const Scurve = () => {
  return (
    <Box sx={{ padding: 3 }}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <ChartComponent data={ManhourData} />
        </Grid>
        <Grid item xs={12} md={6}>
          <ChartComponent data={CostData} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Scurve;

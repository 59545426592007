import React, { useState } from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import DocumentTransmittalTab from "../../DocumentTransmittalTab";

const DocumentTransmittalModal = ({ open, handleClose }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="document-transmittal-modal-title"
      aria-describedby="document-transmittal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "70%",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography
          id="document-transmittal-modal-title"
          variant="h6"
          component="h2"
        >
          Document Transmittal
        </Typography>
        <DocumentTransmittalTab />
        <Box mt={2} display="flex" justifyContent="flex-end">
          <Button variant="contained" color="secondary" onClick={handleClose}>
            Close
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DocumentTransmittalModal;

import { useMsal } from "@azure/msal-react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { GetAllProjectsRoute } from "../../routes";
import { Button, Card, Container, Typography } from "@mui/material";

import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import microsoft_icon from "../../images/login/microsoft_icon.png";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import { loginVerify } from "./api";

const LoginPage = () => {
  const navigate = useNavigate();
  const [accessToken, setAccessToken] = useState(null);
  const { instance, inProgress, accounts } = useMsal();

  const loginRequest = {
    scopes: ["api://3eca0ec5-8973-4200-9146-599fec0e6b00/specialScope"],
  };

  const getAccessToken = async () => {
    // console.log({ inProgress })
    const accessTokenRequest = {
      scopes: [
        `api://${process.env.REACT_APP_AZURE_AD_SERVER_CLIENT_ID}/specialScope`,
      ],
      account: accounts[0],
    };
    if (accounts[0] && inProgress === InteractionStatus.None) {
      return await instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
          // Acquire token silent success
          let accessToken = accessTokenResponse.accessToken;
          setAccessToken(accessToken);
          return accessToken;
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance
              .acquireTokenPopup(accessTokenRequest)
              .then(function (accessTokenResponse) {
                // Acquire token interactive success
                let accessToken = accessTokenResponse.accessToken;
                setAccessToken(accessToken);
                return accessToken;
              })
              .catch(function (error) {
                // Acquire token interactive failure
                console.log(error);
              });
          }
          console.log(error);
        });
    } else {
      return null;
    }
  };

  const handleLogin = () => {
    instance
      .loginPopup({
        ...loginRequest,
      })
      .then(async (res) => {
        await getAccessToken().then(async (res) => {
          // console.log("token response", { res });
        });
        // console.log("login res", res);
        // await loginVerify(res.accessToken)
        //   .then((response) => {
        //     // const role = response.data.role
        //     console.log("function response", response);
        //     // setAu th({ role })
        //     // localStorage.setItem("xvhuuroh", role);
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //   });
        if (res.account?.name || res.account?.username) {
          navigate("/projects");
        }
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Card
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          bgcolor: "White",
          p: 5,
          pb: 8,
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Welcome to ChinookDT
        </Typography>
        <Typography variant="body1">Members only login</Typography>
        <br />
        <Button
          type="submit"
          fullWidth
          variant="outlined"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "white",
          }}
          onClick={handleLogin}
        >
          <img style={{ height: "30px", width: "30px" }} src={microsoft_icon} />
          <Typography variant="body1" style={{ marginLeft: "10px" }}>
            Sign In With Microsoft
          </Typography>
        </Button>
      </Card>
    </Container>
  );
};

export default LoginPage;

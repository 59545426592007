import React, { useEffect, useState } from "react";
import EditableTable from "../../../../../../components/Tables/EditableTable";
import { FabInitialData, fabricationColumns } from "./utils";
import { useParams } from "react-router-dom";
import {
  createFabrication,
  deleteFabrication,
  getFabrication,
  updateFabrication,
} from "./api";
import { secondAxios } from "../../../../../../api/axios";
import TimesheetDialog from "../../../../../../components/Dialogs/TimesheetDialog";

const FabricationTab = () => {
  const { equipId } = useParams();
  const [users, setUsers] = useState([]);
  useEffect(() => {
    // console.log("heree");

    const getAllUsers = async () => {
      const users = (await secondAxios.get("/user")).data;
      const usernames = users.map((u) => u.name);
      setUsers(usernames);
      console.log("users", users);
    };
    getAllUsers();
  }, []);
  return (
    <div>
      <EditableTable
        name="Fabrication"
        displayMode="row"
        columns={fabricationColumns(users)}
        enableEditing={true}
        enableCreate={true}
        initialData={FabInitialData}
        api={{
          getItems: getFabrication,
          createItem: createFabrication,
          updateItem: updateFabrication,
          deleteItem: deleteFabrication,
        }}
        parentId={equipId}
      />
      {/* <TimesheetDialog
      //  open={dialogState.timesheet}
      //  handleClose={handleDialogClose}
      //  parentId={selectedItem?.id}
      //  parentName={selectedItem?.document}
      //  api={{
      //    getItems: getTimesheetsData,
      //    createItem: createTimesheetData,
      //    updateItem: updateTimesheetData,
      //    deleteItem: deleteTimesheetData,
      //  }}
      /> */}
    </div>
  );
};

export default FabricationTab;

import React from "react";
import { Grid, TextField, Typography } from "@mui/material";
import { MRQDetailsGrid } from "../utils";
import LabelledBox from "../../../../../../../components/widgets/LabelledBox";
import { MCSContext } from "../../MCS.context";

const MrqDetails = () => {
  const { mrqData, project } = React.useContext(MCSContext);

  return (
    <LabelledBox label="MRQ Details">
      <Grid container spacing={2} columns={{ xs: 6.8 }} sx={{ width: "900px" }}>
        {MRQDetailsGrid.map((item, index) => (
          <React.Fragment key={index}>
            <Grid item xs={item.grid}>
              <Typography>{item.label}</Typography>
              {/* <Box sx={{ backgroundColor: "red" }}>{item.label}</Box> */}
            </Grid>
            {/* <Grid item xs={item.label === "From" || item.label === "Title" ? 2.6 : 1} > */}
            <Grid item xs={item.label === "Title" ? 2.6 : 1}>
              {/* <Typography color={"GrayText"}>Values</Typography> */}
              <TextField
                id="outlined-basic"
                variant="outlined"
                hiddenLabel
                size="small"
                disabled
                fullWidth
                value={
                  item.field === "project"
                    ? project.name
                    : mrqData?.[item.field]
                }
                inputProps={{ sx: { padding: "2px", pl: "5px" } }}
              />
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </LabelledBox>
  );
};

export default MrqDetails;

import React, { createContext } from "react";
import { getMrqData, getMrqPos, getMrqs } from "./api";
import { getMrqBid } from "./BidTab/api";
import { getBidTableRows } from "./BidTab/BidTable/BidTableBody/getRows";

const MCSContext = createContext({
  mrqList: [],
  mrqSelection: {},
  mrqData: {},
  currentBidVendor: [],
  handleMrqChange: () => {},
  handleRevisionChange: () => {},
  setMrqList: () => {},
  setMrqData: () => {},
  selectedBidVendors: [],
  setSelectedVendors: () => {},
  bidData: [],
  setBidData: () => {},
  BidTableRows: [],
  setBidTableRows: () => {},
});

function MCSContextProvider({ children, project }) {
  const [currentTab, setCurrentTab] = React.useState(0);
  const [mrqList, setMrqList] = React.useState([]);
  const [mrqSelection, setMrqSelection] = React.useState({
    selectedMrq: "",
    revisions: [],
    selectedRevision: "",
  });
  const [mrqData, setMrqData] = React.useState({});
  const [selectedBidVendors, setSelectedVendors] = React.useState([]);
  const [bidData, setBidData] = React.useState([]);
  const [BidTableRows, setBidTableRows] = React.useState([]);

  // Handler for selecting MRQ
  const handleMrqChange = (event) => {
    const mrqId = event.target.value;
    const selectedMrq = mrqList.find((mrq) => mrq.id === mrqId);
    const revisions = selectedMrq?.revs ? selectedMrq.revs : [];
    setMrqSelection({
      selectedMrq: mrqId,
      revisions: revisions,
      selectedRevision: "",
    });
  };

  // Handler for selecting revision
  const handleRevisionChange = (event) => {
    setMrqSelection({ ...mrqSelection, selectedRevision: event.target.value });
  };

  React.useEffect(() => {
    const fetchMrqList = async () => {
      const data = await getMrqs({ projectId: project.id }).then((res) =>
        setMrqList(res)
      );
    };

    fetchMrqList();
  }, []);

  React.useEffect(() => {
    const fetchMrqThenBidData = async () => {
      const mrq = await getMrqData(
        mrqSelection.selectedRevision || mrqSelection.selectedMrq
      );
      setMrqData(mrq);
      setSelectedVendors(mrq.vendors?.map((v) => v.name));

      const bids = await getMrqBid(
        mrqSelection.selectedRevision || mrqSelection.selectedMrq
      );
      const existedPos = await getMrqPos(
        mrqSelection.selectedRevision || mrqSelection.selectedMrq
      );
      setBidData(bids ? bids : []);

      setBidTableRows();
      setBidTableRows(
        getBidTableRows({
          mrqData: mrq ? mrq : {},
          bidData: bids ? bids : [],
          existedPos,
        })
      );
    };

    fetchMrqThenBidData();
  }, [mrqSelection]);

  return (
    <MCSContext.Provider
      value={{
        currentTab,
        setCurrentTab,
        project,

        mrqList,
        setMrqList,
        mrqSelection,
        setMrqSelection,
        mrqData,
        setMrqData,
        handleMrqChange,
        handleRevisionChange,
        selectedBidVendors,
        setSelectedVendors,
        bidData: bidData ? bidData : [],
        setBidData,
        BidTableRows,
        setBidTableRows,
      }}
    >
      {children}
    </MCSContext.Provider>
  );
}

export { MCSContextProvider, MCSContext };

import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import React from "react";
import { lightTable } from "../../../../../../../../styles/muiProps";
import { DrawingBomContext } from "../../DrawingBom.context";
import { groupingTableCols } from "./cols";

export default function GroupingTable() {
  const { consolidatedMaterialsData } = React.useContext(DrawingBomContext);

  const table = useMaterialReactTable({
    columns: groupingTableCols,
    data: consolidatedMaterialsData,
    ...lightTable,

    muiTableContainerProps: { sx: { maxHeight: "250px" } },
  });

  return <MaterialReactTable table={table} />;
}

import { Dialog, DialogContent, Typography } from "@mui/material";
import React, { useContext } from "react";
import { timesheetColumns } from "./utils";
import { AuthContext } from "../../../context/authContext";
import { useMsal } from "@azure/msal-react";

// import { createTimesheet, deleteTimesheet, getTimesheets, updateTimesheet } from './api'
import EditableTable from "../../Tables/EditableTable";

const initialData = {
  date: "",
  progress: 0,
  hours: 0,
  workDone: "",
};

const TimesheetDialog = ({
  open,
  handleClose,
  task,
  parentId,
  parentName,
  api,
}) => {
  // const { instance, accounts } = useMsal();
  // const username = accounts[0]?.name || "Me";
  const { accounts } = useMsal();
  const username = accounts[0].username;
  //   console.log("ttttttttt", username);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"lg"}>
      <DialogContent>
        <Typography variant="h5" gutterBottom>
          Timesheets for {parentName || ""}
        </Typography>
        <EditableTable
          name="Timesheet"
          displayMode="row"
          columns={timesheetColumns({ username })}
          enableEditing={true}
          enableCreate={true}
          initialData={initialData}
          api={{
            getItems: api.getItems,
            createItem: api.createItem,
            updateItem: api.updateItem,
            deleteItem: api.deleteItem,
          }}
          parentId={parentId}
        />
      </DialogContent>
    </Dialog>
  );
};

export default TimesheetDialog;

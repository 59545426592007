import { Box, Grid } from "@mui/material";
import React from "react";
import LabelledBox from "../../../../../../components/widgets/LabelledBox";
import DrawingSelection from "../DrawingRegisterTab/components/DrawingSelection";
import TabControl from "./components/TabControl";
import ConsolidateModal from "./components/ConsolidateModal";
import DrawingBomTable from "./components/Table/DrawingBomTable";
import { DrawingBomContext } from "./DrawingBom.context";

export default function Main() {
  const { selectedDrawings, handleAddingNewDrawing } =
    React.useContext(DrawingBomContext);

  return (
    <Box>
      <Grid container sx={{ position: "relative" }}>
        <Grid item xs={6}>
          <LabelledBox sx={{ ml: 5 }}>
            <DrawingSelection
              selectedDrawings={selectedDrawings}
              handleAddingNewDrawing={handleAddingNewDrawing}
            />
          </LabelledBox>
        </Grid>

        <Grid item xs={6}>
          <LabelledBox sx={{ position: "absolute", right: 40, top: 25 }}>
            <TabControl />

            <ConsolidateModal />
          </LabelledBox>
        </Grid>
      </Grid>

      <Box sx={{ mx: 5 }}>
        <DrawingBomTable />
      </Box>
    </Box>
  );
}

import React from "react";
import Chat from "./Chat";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { BidChatContext } from "../../pages/client/AllEquipments/CommonFeatures/MCS3/BidTab/BidChat.context";

export default function BidChat() {
  const { vendors } = React.useContext(BidChatContext);
  const tabs = vendors.map((v) => {
    return { label: v?.name, panelMsgs: v.msgs || [] };
  });

  return (
    <Chat
      tabs={tabs}
      btnLabel="Chat with Vendors"
      tabIcon={<AccountCircleIcon sx={{ fontSize: 40 }} />}
    />
  );
}

// msgs:[
// {
//   id: 1,
//   from: 'me',
//   body: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis quis nesciunt harum autem adipisci vel consectetur, doloribus et. Porro iste id impedit corporis repellat expedita labore unde aut illo tempore.',
//   seen: true,
//   createdAt: '2024-2-27 : 4:45pm',
// },

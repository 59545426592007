import axios, { secondAxios } from "../../../../../../api/axios";

export const getSchedule = async (projectId) => {
  const { data } = await secondAxios.get("schedule4d/" + projectId);
  return data;
};

export const updateSchedule = async (body) => {
  const { data } = await secondAxios.patch("schedule4d", body);
  return data;
};
export const update4D = async (id, body) => {
  console.log(id, body);
  const { data } = await secondAxios.patch(`schedule4d/${id}`, body);
  return data;
};

export const getTrackItem = async (raciId) => {};

// export const update = async (data) => {
//   const updateTaskApi = "pms/update";
//   console.log("updating", data)
//   return await axios.post(updateTaskApi, data)
// }

// export const refresh = async (equipId) => {
//   const refreshTaskApi = "pms/refresh/" + equipId;
//   return await axios.get(refreshTaskApi)
// }

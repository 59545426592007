import { Box, Typography } from "@mui/material";
import React, { ReactNode } from "react";
import { labelledBoxStyles } from "../../styles/muiProps";

const LabelledBox = ({ label, children, sx }) => {
  return (
    <Box sx={{ ...labelledBoxStyles, ...sx }}>
      <Typography sx={{ fontWeight: "bold", color: "#565D6DFF", pb: 1 }}>
        {label}
      </Typography>
      <Box>{children}</Box>
    </Box>
  );
};

export default LabelledBox;

import React, { useEffect, useState } from "react";

import { MaterialReactTable } from "material-react-table";
import { columns } from "./columns";
import paths from "../../../../../api/paths.api";
import { useNavigate } from "react-router-dom";
import { projectTableProps } from "./utils";
import { getLink } from "../../../../../components/CustomIcons/EquipsTypesIcons";
import ForgeViewerModal from "../../../AllEquipments/CommonFeatures/PCSTab/Table/ViewerModal";
import { secondAxios } from "../../../../../api/axios";

export default function ProjectsTable({ data, state }) {
  const navigate = useNavigate();
  const [documents, setSharepointDocuments] = useState([]);
  const [viewerState, setViewerState] = useState(false);
  const [urn, setUrn] = useState();
  const [urnLoaded, setUrnLoaded] = useState(false);

  useEffect(() => {
    const getDocuments = async () => {
      const documentsData = (await secondAxios.get("/sharepoint")).data;
      console.log(documentsData);

      const documents = documentsData.map((d) => ({
        name: d.name.split("-")[1],
        urn: d.urn,
      }));
      console.log("documentsss ", documents);

      setSharepointDocuments(documents);
      setUrnLoaded(true);
    };
    getDocuments();
  }, []);

  const goToProject = (project) => {
    const equipment = project.projects?.[0]
    let link0 = getLink(equipment.type) + "/" + equipment.id;
    navigate(paths.myProjects + "/" + project.id + "/equipments/" + link0);
  };

  const onClick = (id, equipment, tab) => {
    if (tab === "preview") {
      let prevUrn;
      prevUrn = documents.find((d) => d.name === "3d")?.urn;

      setUrn(prevUrn);
      setViewerState(true);
    } else {
      let link0 = getLink(equipment.type) + "/" + equipment.id;
      console.log(
        paths.myProjects +
        "/" +
        id +
        "/equipments/" +
        link0 +
        "#main_nav=" +
        tab.toUpperCase()
      );
      window.open(
        paths.myProjects + "/" + id + "/equipments/" + link0 + tab,
        "_blank"
      );
    }
  };

  return (
    <>
      <MaterialReactTable
        data={data}
        columns={columns(onClick, urnLoaded)}
        defaultColumn={{ minSize: 20 }}
        enableEditing={true}
        positionActionsColumn="last"
        layoutMode="grid"
        editDisplayMode="row"
        {...projectTableProps}
        muiTableBodyRowProps={({ row }) => ({
          onClick: (event) => {
            const target = event.target;
            if (
              !target.closest(".MuiButtonBase-root") &&
              !target.closest("a")
            ) {
              console.log(row.original);
              goToProject(row.original);
            }
          },
          sx: {
            cursor: "pointer", //change the cursor when adding an onClick
          },
        })}
        muiToolbarAlertBannerProps={
          state.isError
            ? {
              color: "error",
              children: "Error loading data",
            }
            : undefined
        }
        state={{
          isLoading: state.isLoading, //cell skeletons and loading overlay
          showAlertBanner: state.isError,
        }}
      />
      <ForgeViewerModal
        open={viewerState}
        onClose={() => {
          setViewerState(false);
        }}
        urn={urn}
      />
    </>
  );
}

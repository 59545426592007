import { Box, Button, Paper, Tab, Tabs } from "@mui/material";
import React from "react";
import ChatIcon from "@mui/icons-material/ForumRounded";
import CloseIcon from "@mui/icons-material/CloseRounded";
import ChatBody from "./ChatBody";
import { BidChatContext } from "../../../pages/client/AllEquipments/CommonFeatures/MCS3/BidTab/BidChat.context";
import { msgs1 } from "./test";

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function Chat({ tabs, btnLabel, tabIcon }) {
  const { openChatVendorIndex, setOpenChatVendorIndex } =
    React.useContext(BidChatContext);

  const handleChange = (event, newValue) => {
    setOpenChatVendorIndex(newValue);
  };
  return (
    <>
      {openChatVendorIndex || openChatVendorIndex === 0 ? (
        <Paper
          sx={{
            position: "absolute",
            bottom: 0,
            right: 0,
            width: "50%",
            height: "50%",
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            borderTopLeftRadius: "7px",
            borderTopRightRadius: "7px",
            border: "1px solid #ccc",
            borderBottom: "none",
          }}
        >
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={openChatVendorIndex}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{
              borderRight: 1,
              borderColor: "divider",
              width: "17.5%",
              height: "100%",
              backgroundColor: "hsla(250, 86%, 97%, 1)",
            }}
          >
            {tabs.map((tab) => (
              <Tab
                label={tab.label}
                {...a11yProps(0)}
                icon={tabIcon}
                iconPosition="start"
                sx={{
                  display: "flex",
                  justifyContent: "left",
                }}
                key={tab.label}
              />
            ))}
          </Tabs>
          {/* Tab panels */}
          {tabs.map((tab, index) => (
            <TabPanel value={openChatVendorIndex} index={index}>
              <ChatBody msgs={msgs1} />
            </TabPanel>
          ))}

          <ChatBtn setOnClick={false} label="Close Chat" icon={<CloseIcon />} />
        </Paper>
      ) : (
        <ChatBtn setOnClick={0} label={btnLabel} icon={<ChatIcon />} />
      )}
    </>
  );
}

const ChatBtn = ({ setOnClick, label, icon }) => {
  const { setOpenChatVendorIndex } = React.useContext(BidChatContext);
  return (
    <Box sx={{ position: "absolute", bottom: 20, right: 20, zIndex: 6 }}>
      <Button
        variant="contained"
        endIcon={icon}
        onClick={() => {
          setOpenChatVendorIndex(setOnClick);
        }}
      >
        {label}
      </Button>
    </Box>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            p: 0,
            position: "absolute",
            bottom: 0,
            right: 0,
            width: "82.5%",
            height: "100%",
            overflow: "auto",
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

import {
  Badge,
  Box,
  Button,
  Chip,
  IconButton,
  Link,
  ListItemIcon,
  MenuItem,
} from "@mui/material";
import ChatIcon from "@mui/icons-material/Chat";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import PostAddIcon from "@mui/icons-material/PostAdd";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import AttachEmailIcon from "@mui/icons-material/AttachEmail";
import PreviewIcon from "@mui/icons-material/Preview";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";

export const PCSColumns = (
  handleButtonClick,
  allUsers,
  currentUser,
  sharepointDocuments
) => [
  {
    accessorKey: "document",
    header: `Document`,
    enableEditing: true,
    Cell: ({ cell, row }) => {
      // console.log('doc cell', cell.getValue());
      return cell.getValue();
    },
    muiEditTextFieldProps: () => ({
      children: sharepointDocuments.map((doc) => (
        <MenuItem key={doc.name} value={doc.name}>
          {doc.name}
        </MenuItem>
      )),
      select: true,
    }),
  },
  {
    accessorKey: "revNum",
    header: `Rev #`,
    enableEditing: (row) => {
      if (row.original.task === "") return true;
      else if (currentUser)
        return row?.original?.task_members?.includes(currentUser);
      else return false;
    },
    size: 50,
  },
  // {
  //   accessorKey: 'status',
  //   header: `Status`,
  //   enableEditing: (row) => {
  //     if (row.original.task === '') return true;
  //     else if (currentUser)
  //       return row?.original?.task_members?.includes(currentUser);
  //     else return false;
  //   },
  //   size: 50,
  // },
  {
    accessorKey: "responsible",
    header: "Responsible",
    size: 90,
    enableSorting: false,
    muiEditTextFieldProps: () => ({
      children: allUsers.map((user) => (
        <MenuItem key={user.email} value={user.email}>
          {user.email}
        </MenuItem>
      )),
      select: true,
    }),
    Cell: ({ cell, row }) => {
      return cell.getValue();
    },
  },
  {
    accessorKey: "approver",
    header: `Approver`,
    // enableEditing: false,
    size: 100,
    enableColumnFilter: false,
    enableSorting: false,
    muiEditTextFieldProps: () => ({
      children: allUsers.map((user) => (
        <MenuItem key={user.email} value={user.email}>
          {user.email}
        </MenuItem>
      )),
      select: true,
    }),
    Cell: ({ row }) => (
      <IconButton
        onClick={() => handleButtonClick("acc", row)}
        // disabled={project_members?.includes(currentUser) || projectLeads.includes(currentUser) ? false : true}
      >
        {/* <Badge badgeContent={2} color="primary" > */}
        <FormatListBulletedIcon />
        {/* </Badge> */}
      </IconButton>
    ),
  },
  {
    accessorKey: "contributor",
    header: `Contributor`,
    size: 100,
    // enableEditing: false,
    enableColumnFilter: false,
    enableSorting: false,
    Cell: ({ row }) => (
      <IconButton
        onClick={() => handleButtonClick("acc", row)}
        // disabled={project_members?.includes(currentUser) || projectLeads.includes(currentUser) ? false : true}
      >
        {/* <Badge badgeContent={2} color="primary" > */}
        <FormatListBulletedIcon />
        {/* </Badge> */}
      </IconButton>
    ),
    muiEditTextFieldProps: () => ({
      children: (allUsers || [])?.map((user) => (
        <MenuItem key={user.email} value={user.email}>
          {user.email}
        </MenuItem>
      )),
      select: true,
      SelectProps: {
        multiple: true,
        renderValue: (selected) => {
          console.log({ selected });
          return (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value, index) => (
                <Chip
                  key={index}
                  label={
                    allUsers?.find((member) => member.email === value)?.email
                  }
                />
              ))}
            </Box>
          );
        },
      },
    }),
  },
  {
    accessorKey: "informed",
    header: "Informed",
    enableEditing: true,
    enableColumnFilter: false,
    enableColumnActions: false,
    enableSorting: false,
    size: 80,
    enableSorting: false,
    Cell: ({ row }) => (
      <IconButton
        onClick={() => handleButtonClick("acc", row)}
        // disabled={project_members?.includes(currentUser) || projectLeads.includes(currentUser) ? false : true}
      >
        {/* <Badge badgeContent={2} color="primary" > */}
        <FormatListBulletedIcon />
        {/* </Badge> */}
      </IconButton>
    ),
    muiEditTextFieldProps: () => ({
      children: (allUsers || [])?.map((user) => (
        <MenuItem key={user.email} value={user.email}>
          {user.email}
        </MenuItem>
      )),
      select: true,
      SelectProps: {
        multiple: true,
        renderValue: (selected) => {
          console.log({ selected });
          return (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value, index) => (
                <Chip
                  key={index}
                  label={
                    allUsers?.find((member) => member.email === value)?.email
                  }
                />
              ))}
            </Box>
          );
        },
      },
    }),
  },
  {
    accessorKey: "docLink",
    header: `Doc Link`,
    size: 30,
    enableSorting: false,
    enableColumnActions: false,
    enableSorting: false,
    Cell: ({ row }) => (
      <IconButton onClick={() => handleButtonClick("doclink", row)}>
        <FileOpenIcon />
      </IconButton>
    ),
  },
  {
    accessorKey: "prev",
    header: "View",
    enableEditing: false,
    enableColumnFilter: false,
    size: 50,
    // Header: () => <PreviewIcon />,
    enableColumnActions: false,
    enableSorting: false,
    enableSorting: false,
    Cell: ({ row }) => (
      <IconButton onClick={() => handleButtonClick("preview", row)}>
        <PreviewIcon />
      </IconButton>
    ),
  },
  {
    accessorKey: "mail",
    header: "Mail",
    enableEditing: false,
    enableColumnFilter: false,
    size: 30,
    // Header: () => <AttachEmailIcon />,
    enableColumnActions: false,
    enableSorting: false,
    enableSorting: false,
    Cell: ({ row }) => (
      <IconButton onClick={() => handleButtonClick("mail", row)}>
        <AttachEmailIcon />
      </IconButton>
    ),
  },
  {
    accessorKey: "chat",
    header: `Chat`,
    enableEditing: false,
    enableSorting: false,
    enableColumnFilter: false,
    size: 20,
    enableColumnActions: false,
    enableSorting: false,
    enableSorting: false,
    Cell: ({ row }) => (
      <IconButton onClick={() => handleButtonClick("timesheet", row)}>
        <ChatIcon />
      </IconButton>
    ),
  },
  {
    accessorKey: "timesheet",
    header: `Timesheet`,
    enableEditing: false,
    enableSorting: false,
    enableColumnFilter: false,
    size: 20,
    enableColumnActions: false,
    enableSorting: false,
    enableSorting: false,
    // Header: () => <PostAddIcon />,
    Cell: ({ row }) => (
      <IconButton onClick={() => handleButtonClick("timesheet", row)}>
        <PostAddIcon />
      </IconButton>
    ),
  },
];

export const RACIColumns = ({
  handleButtonClick,
  currentUser,
  allUsers,
  sharepointDocuments,
  urnLoaded,
}) => [
  {
    accessorKey: "document",
    header: `Document`,
    enableEditing: true,
    Cell: ({ cell, row }) => {
      // console.log('doc cell', cell.getValue());
      return cell.getValue();
    },
    size: 30,
  },
  {
    accessorKey: "docNum",
    header: `Document #`,
    enableEditing: true,
    Cell: ({ cell, row }) => {
      // console.log('doc cell', cell.getValue());
      return cell.getValue();
    },
    size: 30,
  },
  {
    accessorKey: "revNum",
    header: `Rev #`,
    enableEditing: (row) => {
      if (row.original.task === "") return true;
      else if (currentUser)
        return row?.original?.task_members?.includes(currentUser);
      else return false;
    },
    size: 30,
  },
  // {
  //   accessorKey: "description",
  //   header: `Description`,
  //   enableEditing: true,
  //   size: 30,
  // },
  {
    accessorKey: "docLink",
    header: `Doc Link`,
    size: 30,
    Cell: ({ cell }) => (
      <>
        {cell.getValue() ? (
          <Link underline="none" href={cell.getValue()}>
            Link
          </Link>
        ) : (
          <></>
        )}
      </>
    ),
  },
  // {
  //   accessorKey: "docNum",
  //   header: `Doc Num`,
  //   enableEditing: true,
  //   size: 30,
  // },

  {
    accessorKey: "progress",
    header: `progress`,
    type: "number",

    enableEditing: true,
    Cell: ({ cell, row }) => {
      console.log("doc cell", row.original);
      const timesheets = row.original?.timesheets;
      console.log(timesheets, timesheets?.length);
      let progress = timesheets?.reduce((acc, t) => acc + t?.progress, 0) || 0;
      console.log({ progress });
      return `${progress}%`;
    },
    // muiEditTextFieldProps: () => ({
    //   children: [
    //     <MenuItem key={1} value={"2D"}>
    //       Not Started
    //     </MenuItem>,
    //     <MenuItem key={1} value={"3D"}>
    //       In Progress
    //     </MenuItem>,
    //     <MenuItem key={1} value={"3D"}>
    //       Under Review
    //     </MenuItem>,
    //     <MenuItem key={1} value={"3D"}>
    //       Done
    //     </MenuItem>,
    //   ],
    //   select: true,
    // }),
    size: 30,
  },
  {
    accessorKey: "responsible",
    header: "R",
    enableColumnActions: false,
    enableSorting: false,
    size: 90,
    muiEditTextFieldProps: () => ({
      children: allUsers?.map((user) => (
        <MenuItem key={user.email} value={user.email}>
          {user.email}
        </MenuItem>
      )),
      select: true,
    }),
    // Cell: ({ cell, row }) => {
    //   // console.log('cell', cell.getValue());
    //   const timesheets = row.original?.timesheets
    //   if (timesheets?.length) {
    //     const progressSum = timesheets.reduce((sum, timesheet) => sum + timesheet.progress, 0)
    //     console.log({ progressSum })
    //   }
    //   return cell.getValue();
    // },
  },
  {
    accessorKey: "accountables",
    header: `A`,
    enableEditing: false,
    size: 50,
    enableColumnActions: false,
    enableSorting: false,
    enableColumnFilter: false,
    Cell: ({ row }) => (
      <IconButton onClick={() => handleButtonClick("acc", row)}>
        <FormatListBulletedIcon />
      </IconButton>
    ),
  },
  {
    accessorKey: "consulted",
    header: `C`,
    size: 50,
    enableEditing: false,
    enableColumnActions: false,
    enableSorting: false,
    enableColumnFilter: false,
    Cell: ({ row }) => (
      <IconButton onClick={() => handleButtonClick("cons", row)}>
        <FormatListBulletedIcon />
      </IconButton>
    ),
  },
  {
    accessorKey: "informed",
    header: "I",
    enableEditing: false,
    enableColumnActions: false,
    enableSorting: false,
    enableColumnFilter: false,
    size: 50,
    Cell: ({ row }) => (
      <IconButton onClick={() => handleButtonClick("inf", row)}>
        <FormatListBulletedIcon />
      </IconButton>
    ),
  },
  {
    accessorKey: "mail",
    header: "Mail",
    enableColumnActions: false,
    enableSorting: false,
    enableEditing: false,
    enableColumnFilter: false,
    size: 50,
    Header: () => <AttachEmailIcon />,
    Cell: ({ row }) => (
      <IconButton onClick={() => handleButtonClick("mail", row)}>
        <AttachEmailIcon />
      </IconButton>
    ),
  },
  {
    accessorKey: "chat",
    header: "Chat",
    enableColumnActions: false,
    enableSorting: false,
    enableEditing: false,
    enableColumnFilter: false,
    size: 50,
    Header: () => <ChatIcon />,
    Cell: ({ row }) => (
      <IconButton onClick={() => handleButtonClick("comments", row)}>
        <ChatIcon />
      </IconButton>
    ),
  },
  // {
  //   accessorKey: "prev",
  //   header: "prev",
  //   enableColumnActions: false,
  //   enableSorting: false,
  //   enableEditing: false,
  //   enableColumnFilter: false,
  //   size: 50,
  //   Header: () => <PreviewIcon />,
  //   Cell: ({ row }) => (
  //     <IconButton
  //       onClick={() => {
  //         urnLoaded && handleButtonClick("preview", row);
  //       }}
  //     >
  //       {urnLoaded ? <PreviewIcon /> : <HourglassBottomIcon />}
  //     </IconButton>
  //   ),
  // },
  {
    accessorKey: "timesheet",
    header: `Timesheet`,
    enableEditing: false,
    enableSorting: false,
    enableColumnFilter: false,
    enableColumnActions: false,
    size: 20,
    Header: () => <PostAddIcon />,
    Cell: ({ row }) => (
      <IconButton
        onClick={() => handleButtonClick("timesheet", row)}
        // disabled={project_members?.includes(currentUser) || projectLeads.includes(currentUser) ? false : true}
      >
        <PostAddIcon />
      </IconButton>
    ),
  },
];

import React from "react";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import PostAddIcon from "@mui/icons-material/PostAdd";
import AssignedEngsForm from "./AssignedEngsForm";
import {
  TypesIcon,
  getLink,
} from "../../../../../../components/CustomIcons/EquipsTypesIcons";
import UpdateEquipNameForm from "./UpdateEquipNameForm";
import DeleteEquipBtn from "./DeleteEquipBtn";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import paths from "../../../../../../api/paths.api";
// const

export default function EquipsTable({ project }) {
  const [equips, setEquips] = React.useState([project]);

  const [editedEquip, setEditedEquip] = React.useState({});

  const navigate = useNavigate();

  function onDeleteEquip(equip) {
    let newList = equips.filter((obj) => obj.id !== equip.id);

    setEquips(newList);
  }

  const handleUpdate = (equip) => {
    setEditedEquip(equip);
  };

  const openEquipment = (row) => {
    // console.log("project", project.projects[0].id);

    // console.log("row", row);

    let link0 =
      getLink(row.type ? row.type : "Tank") + "/" + project.projects[0].id;
    let link = "/equipment" + getLink(row.type);
    let rev = 0;
    console.log("loink", { link0 });
    navigate(link0, { state: { project, equipment: { ...row, rev } } });
  };

  const goBackToProjects = () => {
    navigate(paths.myProjects);
  };

  return (
    <TableContainer
      component={Paper}
      sx={{ width: "90%", margin: "auto", paddingLeft: 2, paddingRight: 2 }}
    >
      {project && project?.projects?.length ? (
        <Table sx={{ minWidth: 450 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold", fontSize: "17px" }}>
                Equipment Type
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", fontSize: "17px" }}
                align="left"
              >
                Name
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", fontSize: "17px" }}
                align="center"
              >
                Assigned Eng.
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", fontSize: "17px" }}
                align="center"
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {equips?.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  onClick={() => openEquipment(row.type, row.name, project)}
                  sx={{ fontWeight: "bold", fontSize: "16px" }}
                >
                  <TypesIcon type={row.type ? row.type : "Tank"} />
                  {row.type ? row.type : "Tank"}
                </TableCell>
                <TableCell align="left">
                  {row.id === editedEquip.id ? (
                    <UpdateEquipNameForm
                      equip={row}
                      project={project}
                      setEditedEquip={setEditedEquip}
                    />
                  ) : (
                    <>
                      - {row.name}{" "}
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        sx={{
                          marginRight: 1,
                        }}
                        onClick={() => handleUpdate(row)}
                      >
                        <EditIcon color="success" />
                      </IconButton>{" "}
                    </>
                  )}
                </TableCell>
                <TableCell align="center">
                  <AssignedEngsForm />
                </TableCell>
                <TableCell align="right">
                  {/* <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => openEquipmentNew(row)}
                  >
                    <PostAddIcon color="primary" />
                  </IconButton> */}
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => openEquipment(row)}
                  >
                    <FileOpenIcon color="primary" />
                  </IconButton>
                  {/* <DeleteEquipBtn
                    equip={row}
                    project={project}
                    onDeleteEquip={onDeleteEquip}
                  /> */}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <Box
          sx={{
            padding: 3,
            fontSize: "22px",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          No Equipments yet.
        </Box>
      )}
      {/* project form next */}
      <Box sx={{ overflow: "hidden", padding: 2 }}>
        <Button
          variant="contained"
          sx={{ color: "#fff", float: "right" }}
          onClick={goBackToProjects}
        >
          Go Back To Projects
        </Button>
      </Box>
    </TableContainer>
  );
}

import axios, { secondAxios } from "../../../../api/axios";

const getPlants = "/plant/";
// const getProjects = "/project/";

export const getAllProjects = async () => {
  try {
    const { data } = await secondAxios.get(getPlants);
    console.log("plant data", data);
    // const { data: projData } = await secondAxios.get(getProjects);
    // console.log("project data", projData);

    console.log({ data });
    return { data, error: null };
  } catch (error) {
    console.error("Error fetching data:", error);
    return { data: null, error };
  }
};

import { secondAxios } from "../../../../api/axios";

export const getProjectById = async ({ projectId }) => {
  const response = await secondAxios.get(
    `/project/${projectId}?revisions=true`
  );
  console.log("ressssss", { response });
  if (typeof response.data?.data === "string") {
    return JSON.parse(response.data?.data);
  }
  return response?.data;
};

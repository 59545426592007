import { Box, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import LabelledBox from "../../../../../../../../../components/widgets/LabelledBox";
import { MRQDetailsInputs } from "./utils";
import { DrawingBomContext } from "../../../DrawingBom.context";

export default function MrqForm() {
  const { mrqDetails, onMrqDetailsChange } =
    React.useContext(DrawingBomContext);

  return (
    <Box>
      <LabelledBox label="MRQ Details">
        <Grid
          container
          spacing={2}
          columns={{ xs: 6.8 }}
          sx={{ width: "900px" }}
        >
          {MRQDetailsInputs.map((item, index) => (
            <React.Fragment key={index}>
              <Grid item xs={item.grid}>
                <Typography>{item.label}</Typography>
                {/* <Box sx={{ backgroundColor: "red" }}>{item.label}</Box> */}
              </Grid>
              {/* <Grid item xs={item.label === "From" || item.label === "Title" ? 2.6 : 1} > */}
              <Grid item xs={item.label === "Title" ? 2.6 : 1}>
                {/* <Typography color={"GrayText"}>Values</Typography> */}
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  hiddenLabel
                  size="small"
                  disabled={item.field === "project" ? true : false}
                  fullWidth
                  name={item.field}
                  type={item.field === "mrqDate" ? "date" : "text"}
                  autoComplete="off"
                  value={mrqDetails[item.field]}
                  inputProps={{ sx: { padding: "2px", pl: "5px" } }}
                  onChange={onMrqDetailsChange}
                />
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </LabelledBox>
    </Box>
  );
}

import React, { useEffect, useState } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Stack,
  Button,
} from "@mui/material";
import { useParams } from "react-router-dom";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { BootstrapInput } from "../../../../../../components/widgets/BootstrapInput";
import { getAllProjectPos, getReconcileMaterials } from "./api";

const initialState = {
  poNumber: "",
  revision: "",
  sheet: "",
};
const POSelection = ({ setMaterials }) => {
  const { equipId } = useParams();
  const [pos, setPos] = useState([]);

  const [selectedPo, setSelectedPo] = useState(initialState);

  const [revisions, setRevisions] = useState([]);

  const [selectionResult, setSelectionResult] = useState({});

  useEffect(() => {
    getAllProjectPos(equipId, setPos);
  }, []);

  const onDrawingChange = (po) => {
    setSelectionResult(po);
  };

  const handleNumberChange = (event) => {
    const poNumber = event.target.value;
    setSelectedPo({ ...initialState, poNumber });

    const revs = [
      ...new Set(
        pos
          .filter((d) => d.poNumber === poNumber && d.revision !== "")
          .map((d) => d.revision)
      ),
    ];
    setRevisions(revs);
    // if no revisions, fetch po
    if (!revs[0]) {
      const selectedPo = pos.find((d) => d.poNumber === poNumber);
      onDrawingChange(selectedPo);
    }
  };

  const handleRevisionChange = (event) => {
    const revision = event.target.value;

    setSelectedPo({ ...selectedPo, revision });

    const x = pos.find(
      (d) => d.poNumber === selectedPo.poNumber && d.revision === revision
    );
    onDrawingChange(x);
  };

  const onAddSelectedDrawing = async () => {
    if (!selectionResult.id) {
      alert("Please select a specific drawing to be added.");
    } else {
      const materials = await getReconcileMaterials({
        parent: "po",
        parentId: selectionResult.id,
      });

      setMaterials(materials);
    }
  };

  // console.log(pos);
  return (
    <Stack direction={"row"}>
      <FormControl
        variant="standard"
        size="small"
        sx={{ m: 1, ml: 0, mt: 0, minWidth: 120 }}
        margin="normal"
      >
        <InputLabel id="mrq-label" shrink sx={{ fontSize: "1.1rem" }}>
          PO Number
        </InputLabel>
        <Select
          value={selectedPo.poNumber}
          onChange={handleNumberChange}
          input={<BootstrapInput />}
        >
          {Array.from(new Set(pos?.map((d) => d.poNumber))).map((poNumber) => (
            <MenuItem key={poNumber} value={poNumber}>
              {poNumber}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl
        variant="standard"
        size="small"
        sx={{ m: 1, ml: 0, mt: 0, minWidth: 120 }}
        margin="normal"
        disabled={!selectedPo.name}
      >
        <InputLabel id="mrq-label" shrink sx={{ fontSize: "1.1rem" }}>
          Revision
        </InputLabel>
        <Select
          value={selectedPo.revision}
          onChange={handleRevisionChange}
          input={<BootstrapInput />}
        >
          {revisions?.map((rev) => (
            <MenuItem key={rev} value={rev}>
              {rev}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl
        variant="standard"
        size="small"
        sx={{ m: 1, ml: 0, mt: 0, minWidth: 120 }}
        margin="normal"
        disabled={!selectedPo.revision}
      >
        <InputLabel id="mrq-label" shrink sx={{ fontSize: "1.1rem" }}>
          Title
        </InputLabel>
        <Select value={selectedPo.title} input={<BootstrapInput />}></Select>
      </FormControl>

      <Button
        variant="contained"
        sx={{ mt: 3, ml: 3, mb: 2 }}
        startIcon={<AddCircleOutlineIcon />}
        onClick={onAddSelectedDrawing}
      >
        Add
      </Button>
    </Stack>
  );
};

export default POSelection;

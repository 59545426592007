export const scheduleData = [
  {
    id: 1,
    task_name: "Project Start / Finish",
    task_type: "Project Start / Finish",
    planned_start: "2023-11-29",
    planned_end: "2024-01-31",
    actual_start: "",
    actual_end: "",
    progress: 0,
    cost: 450,
    manhours: 125,
    linkId: null,
    linkType: null,
    createdAt: "2023-10-31T10:33:19.000Z",
    updatedAt: "2023-12-02T18:45:20.000Z",
    equipmentId: 33,
  },
  {
    id: 2,
    task_name: "Design calculation",
    task_type: "Engineering",
    planned_start: "2023-12-01",
    planned_end: "2023-12-16",
    actual_start: "2023-12-08",
    actual_end: "",
    progress: 1,
    cost: 75,
    manhours: 11,
    linkId: 128,
    linkType: "Sharepoint",
    createdAt: "2023-10-31T10:33:19.000Z",
    updatedAt: "2023-12-02T18:45:20.000Z",
    equipmentId: 33,
  },
  {
    id: 3,
    task_name: "3D model preparation",
    task_type: "Engineering",
    planned_start: "2023-12-03",
    planned_end: "2023-12-16",
    actual_start: "2023-11-15",
    actual_end: "",
    progress: 1,
    cost: 125,
    manhours: 20,
    linkId: 129,
    linkType: "Sharepoint",
    createdAt: "2023-10-31T10:33:19.000Z",
    updatedAt: "2023-12-02T18:45:20.000Z",
    equipmentId: 33,
  },
  {
    id: 4,
    task_name: "2D drawings W/ BOM",
    task_type: "Engineering",
    planned_start: "2023-12-10",
    planned_end: "2023-12-15",
    actual_start: "2023-12-14",
    actual_end: "2023-11-02",
    progress: 1,
    cost: 245,
    manhours: 30,
    linkId: 130,
    linkType: "Sharepoint",
    createdAt: "2023-10-31T10:33:19.000Z",
    updatedAt: "2023-12-02T18:45:20.000Z",
    equipmentId: 33,
  },
  {
    id: 5,
    task_name: "Shop drawings",
    task_type: "Engineering",
    planned_start: "2023-12-16",
    planned_end: "2023-12-19",
    actual_start: "2023-11-23",
    actual_end: "2023-11-26",
    progress: 1,
    cost: 200,
    manhours: 23,
    linkId: 143,
    linkType: "Sharepoint",
    createdAt: "2023-10-31T10:33:19.000Z",
    updatedAt: "2023-12-02T18:45:20.000Z",
    equipmentId: 33,
  },
  {
    id: 6,
    task_name: "MRQ for Shell & Head",
    task_type: "Engineering",
    planned_start: "2023-12-19",
    planned_end: "2023-12-26",
    actual_start: "2023-11-01",
    actual_end: "2023-11-01",
    progress: 1,
    cost: 0,
    manhours: 0,
    linkId: 5,
    linkType: "Mrq",
    createdAt: "2023-10-31T10:33:19.000Z",
    updatedAt: "2023-12-02T18:45:20.000Z",
    equipmentId: 33,
  },
  {
    id: 7,
    task_name: "MRQ for Nozzles & Others",
    task_type: "Engineering",
    planned_start: "",
    planned_end: "",
    actual_start: "",
    actual_end: "",
    progress: 0,
    cost: 0,
    manhours: 0,
    linkId: null,
    linkType: null,
    createdAt: "2023-10-31T10:33:19.000Z",
    updatedAt: "2023-12-02T18:45:20.000Z",
    equipmentId: 33,
  },
  {
    id: 8,
    task_name: "PO for Head",
    task_type: "PO & Receive Material",
    planned_start: "2023-12-25",
    planned_end: "2023-12-31",
    actual_start: "2023-11-01",
    actual_end: "2023-11-16",
    progress: 1,
    cost: 0,
    manhours: 0,
    linkId: 5,
    linkType: "Mrv",
    createdAt: "2023-10-31T10:33:19.000Z",
    updatedAt: "2023-12-02T18:45:20.000Z",
    equipmentId: 33,
  },
  {
    id: 9,
    task_name: "MRV for Head",
    task_type: "PO & Receive Material",
    planned_start: "2023-12-30",
    planned_end: "2023-12-30",
    actual_start: "2023-11-01",
    actual_end: "2023-11-16",
    progress: 1,
    cost: 0,
    manhours: 0,
    linkId: 5,
    linkType: "Mrv",
    createdAt: "2023-10-31T10:33:19.000Z",
    updatedAt: "2023-12-02T18:45:20.000Z",
    equipmentId: 33,
  },
  {
    id: 39,
    task_name: "Shell & Head welding",
    task_type: "Fabrication & Testing",
    planned_start: "2023-12-30",
    planned_end: "2024-01-05",
    actual_start: "",
    actual_end: "",
    progress: 1,
    cost: 0,
    manhours: 37,
    linkId: 53,
    linkType: "Fabrication",
    createdAt: "2023-11-06T06:24:50.000Z",
    updatedAt: "2023-12-02T18:45:20.000Z",
    equipmentId: 33,
  },
  {
    id: 40,
    task_name: "Nozzles fab & Welding",
    task_type: "Fabrication & Testing",
    planned_start: "",
    planned_end: "",
    actual_start: "",
    actual_end: "",
    progress: 0,
    cost: 0,
    manhours: 0,
    linkId: null,
    linkType: null,
    createdAt: "2023-11-06T06:24:50.000Z",
    updatedAt: "2023-12-02T18:45:20.000Z",
    equipmentId: 33,
  },
  {
    id: 41,
    task_name: "Supports & others Welding",
    task_type: "Fabrication & Testing",
    planned_start: "",
    planned_end: "",
    actual_start: "",
    actual_end: "",
    progress: 0,
    cost: 0,
    manhours: 0,
    linkId: null,
    linkType: null,
    createdAt: "2023-11-06T06:24:50.000Z",
    updatedAt: "2023-12-02T18:45:20.000Z",
    equipmentId: 33,
  },
  {
    id: 42,
    task_name: "NDE & PWHT",
    task_type: "Fabrication & Testing",
    planned_start: "",
    planned_end: "",
    actual_start: "",
    actual_end: "",
    progress: 0,
    cost: 0,
    manhours: 0,
    linkId: null,
    linkType: null,
    createdAt: "2023-11-06T06:24:50.000Z",
    updatedAt: "2023-12-02T18:45:20.000Z",
    equipmentId: 33,
  },
  {
    id: 43,
    task_name: "Hydrotest",
    task_type: "Fabrication & Testing",
    planned_start: "",
    planned_end: "",
    actual_start: "",
    actual_end: "",
    progress: 0,
    cost: 0,
    manhours: 0,
    linkId: null,
    linkType: null,
    createdAt: "2023-11-06T06:24:50.000Z",
    updatedAt: "2023-12-02T18:45:20.000Z",
    equipmentId: 33,
  },
  {
    id: 44,
    task_name: "Painting",
    task_type: "Fabrication & Testing",
    planned_start: "",
    planned_end: "",
    actual_start: "",
    actual_end: "",
    progress: 0,
    cost: 0,
    manhours: 0,
    linkId: null,
    linkType: null,
    createdAt: "2023-11-06T06:24:50.000Z",
    updatedAt: "2023-12-02T18:45:20.000Z",
    equipmentId: 33,
  },
  {
    id: 45,
    task_name: "Documentation",
    task_type: "Fabrication & Testing",
    planned_start: "",
    planned_end: "",
    actual_start: "",
    actual_end: "",
    progress: 0,
    cost: 0,
    manhours: 0,
    linkId: null,
    linkType: null,
    createdAt: "2023-11-06T06:24:50.000Z",
    updatedAt: "2023-12-02T18:45:20.000Z",
    equipmentId: 33,
  },
];
export const Dummy4DDAta = [
  {
    SI_No: 1,
    Description: "Design Calculation",
    Planned_Start: "03/06/2024",
    Planned_Finish: "12/06/2024",
    Actual_Start: "03/06/2024",
    Actual_Finish: "14/06/2024",
    Progress: "10%",
    Estimated_Man_hours: 75,
    Actual_Man_hours: 80,
    Estimated_Cost: "$5,941.50",
  },
  {
    SI_No: 2,
    Description: "2D drawings W/BOM",
    Planned_Start: "15/06/2024",
    Planned_Finish: "15/07/2024",
    Actual_Start: "15/06/2024",
    Actual_Finish: "20/07/2024",
    Progress: "30%",
    Estimated_Man_hours: 140,
    Actual_Man_hours: 165,
    Estimated_Cost: "$11,090.80",
  },
  {
    SI_No: 3,
    Description: "Procurement of Tank plates",
    Planned_Start: "16/07/2024",
    Planned_Finish: "23/07/2024",
    Actual_Start: "21/07/2024",
    Actual_Finish: "30/07/2204",
    Progress: "35%",
    Estimated_Man_hours: 40,
    Actual_Man_hours: 46,
    Estimated_Cost: "$3,168.80",
  },
  {
    SI_No: 4,
    Description: "Procurement of Nozzles",
    Planned_Start: "24/07/2024",
    Planned_Finish: "30/07/2024",
    Actual_Start: "01/08/2024",
    Actual_Finish: "05/08/2024",
    Progress: "40%",
    Estimated_Man_hours: 30,
    Actual_Man_hours: 35,
    Estimated_Cost: "$2,376.60",
  },
  {
    SI_No: 5,
    Description: "Procurement of Structures",
    Planned_Start: "01/08/2024",
    Planned_Finish: "03/08/2024",
    Actual_Start: "06/08/2024",
    Actual_Finish: "08/08/2024",
    Progress: "50%",
    Estimated_Man_hours: 20,
    Actual_Man_hours: 28,
    Estimated_Cost: "$1,584.40",
  },
  {
    SI_No: 6,
    Description: "Fabrication of Tank plates",
    Planned_Start: "04/08/2024",
    Planned_Finish: "16/08/2024",
    Actual_Start: "09/08/2024",
    Actual_Finish: "24/08/2024",
    Progress: "60%",
    Estimated_Man_hours: 95,
    Actual_Man_hours: 105,
    Estimated_Cost: "$7,525.90",
  },
  {
    SI_No: 7,
    Description: "Fabrication of Nozzles",
    Planned_Start: "17/08/2024",
    Planned_Finish: "22/08/2024",
    Actual_Start: "25/08/2024",
    Actual_Finish: "30/08/2024",
    Progress: "75%",
    Estimated_Man_hours: 60,
    Actual_Man_hours: 65,
    Estimated_Cost: "$4,753.20",
  },
  {
    SI_No: 8,
    Description: "Fabrication of Structures",
    Planned_Start: "23/08/2024",
    Planned_Finish: "02/09/2024",
    Actual_Start: "01/09/2024",
    Actual_Finish: "12/09/2024",
    Progress: "80%",
    Estimated_Man_hours: 120,
    Actual_Man_hours: 135,
    Estimated_Cost: "$9,506.40",
  },
  {
    SI_No: 9,
    Description: "NDE",
    Planned_Start: "03/09/2024",
    Planned_Finish: "12/09/2024",
    Actual_Start: "13/09/2024",
    Actual_Finish: "22/09/2024",
    Progress: "90%",
    Estimated_Man_hours: 75,
    Actual_Man_hours: 80,
    Estimated_Cost: "$5,941.50",
  },
  {
    SI_No: 10,
    Description: "Final Inspection",
    Planned_Start: "13/09/2024",
    Planned_Finish: "20/09/2024",
    Actual_Start: "23/09/2024",
    Actual_Finish: "30/09/2024",
    Progress: "95%",
    Estimated_Man_hours: 70,
    Actual_Man_hours: 73,
    Estimated_Cost: "$5,545.40",
  },
  {
    SI_No: 11,
    Description: "Delivering to Site",
    Planned_Start: "21/09/2024",
    Planned_Finish: "30/09/2024",
    Actual_Start: "01/10/2024",
    Actual_Finish: "10/10/2024",
    Progress: "100%",
    Estimated_Man_hours: 50,
    Actual_Man_hours: 54,
    Estimated_Cost: "$3,961.00",
  },
  {
    Total: {
      Estimated_Man_hours: 775,
      Actual_Man_hours: 866,
      Estimated_Cost: "$61,395.49",
    },
  },
];

export const AdjustedData = [
  {
    name: "Design Calculation",
    plannedStart: "06/03/2024",
    plannedEnd: "06/12/2024",
    actualStart: "06/03/2024",
    actualEnd: "06/14/2024",
    progress: "10%",
  },
  {
    name: "2D drawings W/BOM",
    plannedStart: "06/15/2024",
    plannedEnd: "07/15/2024",
    actualStart: "06/15/2024",
    actualEnd: "07/20/2024",
    progress: "30%",
  },
  {
    name: "Procurement of Tank plates",
    plannedStart: "07/16/2024",
    plannedEnd: "07/23/2024",
    actualStart: "07/21/2024",
    actualEnd: "07/30/2024",
    progress: "35%",
  },
  {
    name: "Procurement of Nozzles",
    plannedStart: "07/24/2024",
    plannedEnd: "07/30/2024",
    actualStart: "08/01/2024",
    actualEnd: "08/05/2024",
    progress: "40%",
  },
  {
    name: "Procurement of Structures",
    plannedStart: "08/01/2024",
    plannedEnd: "08/03/2024",
    actualStart: "08/06/2024",
    actualEnd: "08/08/2024",
    progress: "50%",
  },
  {
    name: "Fabrication of Tank plates",
    plannedStart: "08/04/2024",
    plannedEnd: "08/16/2024",
    actualStart: "08/09/2024",
    actualEnd: "08/24/2024",
    progress: "60%",
  },
  {
    name: "Fabrication of Nozzles",
    plannedStart: "08/17/2024",
    plannedEnd: "08/22/2024",
    actualStart: "08/25/2024",
    actualEnd: "08/30/2024",
    progress: "75%",
  },
  {
    name: "Fabrication of Structures",
    plannedStart: "08/23/2024",
    plannedEnd: "09/02/2024",
    actualStart: "09/01/2024",
    actualEnd: "09/12/2024",
    progress: "80%",
  },
  {
    name: "NDE",
    plannedStart: "09/03/2024",
    plannedEnd: "09/12/2024",
    actualStart: "09/13/2024",
    actualEnd: "09/22/2024",
    progress: "90%",
  },
  {
    name: "Final Inspection",
    plannedStart: "09/13/2024",
    plannedEnd: "09/20/2024",
    actualStart: "09/23/2024",
    actualEnd: "09/30/2024",
    progress: "95%",
  },
  {
    name: "Delivering to Site",
    plannedStart: "09/21/2024",
    plannedEnd: "09/30/2024",
    actualStart: "10/01/2024",
    actualEnd: "10/10/2024",
    progress: "100%",
  },
  {
    Total: {
      estimatedManhours: 775,
      actualManhours: 866,
      estimatedCost: "$61,395.49",
    },
  },
];

export const CostData = [
  {
    DAYS: "01/06/2024",
    Estimated_Cost_$: 0,
    Actual_Cost_$: 0,
  },
  {
    DAYS: "03/06/2024",
    Estimated_Cost_$: 5941.5,
    Actual_Cost_$: 6337.6,
  },
  {
    DAYS: "15/06/2024",
    Estimated_Cost_$: 17032.3,
    Actual_Cost_$: 19408.9,
  },
  {
    DAYS: "21/07/2024",
    Estimated_Cost_$: 20201.1,
    Actual_Cost_$: 23053.02,
  },
  {
    DAYS: "01/08/2024",
    Estimated_Cost_$: 22577.7,
    Actual_Cost_$: 25825.72,
  },
  {
    DAYS: "06/08/2024",
    Estimated_Cost_$: 24162.1,
    Actual_Cost_$: 28043.88,
  },
  {
    DAYS: "09/08/2024",
    Estimated_Cost_$: 31688,
    Actual_Cost_$: 36361.97,
  },
  {
    DAYS: "25/08/2024",
    Estimated_Cost_$: 36441.19,
    Actual_Cost_$: 41511.27,
  },
  {
    DAYS: "01/09/2024",
    Estimated_Cost_$: 45947.59,
    Actual_Cost_$: 52205.97,
  },
  {
    DAYS: "13/09/2024",
    Estimated_Cost_$: 51889.09,
    Actual_Cost_$: 58543.57,
  },
  {
    DAYS: "23/09/2024",
    Estimated_Cost_$: 57434.49,
    Actual_Cost_$: 64326.63,
  },
  {
    DAYS: "01/10/2024",
    Estimated_Cost_$: 61395.49,
    Actual_Cost_$: 68604.51,
  },
  {
    DAYS: "05/10/2024",
    Estimated_Cost_$: 61395.49,
    Actual_Cost_$: 68604.51,
  },
  {
    DAYS: "10/10/2024",
    Estimated_Cost_$: 61395.49,
    Actual_Cost_$: 68604.51,
  },
];

export const ManhourData = [
  {
    DAYS: "01/06/2024",
    Planned_Man_hours: 0,
    Actual_Man_hours: 0,
  },
  {
    DAYS: "03/06/2024",
    Planned_Man_hours: 75,
    Actual_Man_hours: 80,
  },
  {
    DAYS: "15/06/2024",
    Planned_Man_hours: 215,
    Actual_Man_hours: 245,
  },
  {
    DAYS: "21/07/2024",
    Planned_Man_hours: 255,
    Actual_Man_hours: 291,
  },
  {
    DAYS: "01/08/2024",
    Planned_Man_hours: 285,
    Actual_Man_hours: 326,
  },
  {
    DAYS: "06/08/2024",
    Planned_Man_hours: 305,
    Actual_Man_hours: 354,
  },
  {
    DAYS: "09/08/2024",
    Planned_Man_hours: 400,
    Actual_Man_hours: 459,
  },
  {
    DAYS: "25/08/2024",
    Planned_Man_hours: 460,
    Actual_Man_hours: 524,
  },
  {
    DAYS: "01/09/2024",
    Planned_Man_hours: 580,
    Actual_Man_hours: 659,
  },
  {
    DAYS: "13/09/2024",
    Planned_Man_hours: 655,
    Actual_Man_hours: 739,
  },
  {
    DAYS: "23/09/2024",
    Planned_Man_hours: 725,
    Actual_Man_hours: 812,
  },
  {
    DAYS: "01/10/2024",
    Planned_Man_hours: 775,
    Actual_Man_hours: 866,
  },
  {
    DAYS: "05/10/2024",
    Planned_Man_hours: 775,
    Actual_Man_hours: 866,
  },
  {
    DAYS: "10/10/2024",
    Planned_Man_hours: 775,
    Actual_Man_hours: 866,
  },
];

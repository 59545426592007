import { Box, Button, Grid, MenuItem, Select, TextField, Typography } from '@mui/material'
import React from 'react'
import { BootstrapInput } from '../../../../../../components/widgets/BootstrapInput'
import { labelledBoxStyles, lightTable } from '../../../../../../styles/muiProps'
import AddIcon from '@mui/icons-material/Add';
import { documentTransmittalColumns, DocumentTransmittalGrid } from './utils';
import { MaterialReactTable } from 'material-react-table';
import LabelledBox from '../../../../../../components/widgets/LabelledBox';

const DocumentTransmittalTab = () => {
    return (
        <div>

            <Box sx={{ ...labelledBoxStyles }}>
                <Box display="flex" alignItems="center" style={{ gap: '16px' }}>
                    <Typography>Document Transmittal No.</Typography>
                    <Select
                        labelId="document-transmittal-label"
                        value="DCT-001"
                        // onChange={handleChange}
                        // label="Document Transmittal"
                        input={<BootstrapInput />}
                    >
                        <MenuItem key={0} value={"DCT-001"}>
                            DCT-001
                        </MenuItem>
                    </Select>

                    <Button
                        variant="contained"
                        startIcon={<AddIcon />}
                    >
                        Add New
                    </Button>
                </Box>
            </Box>

            {/* <br />
            <br /> */}
            <Box sx={{ ...labelledBoxStyles }}>
                <br />
                <Box>
                    <Grid container spacing={2} columns={{ xs: 6.8 }} sx={{ width: "900px" }}>
                        {DocumentTransmittalGrid.map((item, index) => (
                            <React.Fragment key={index}>
                                <Grid item xs={item.grid}>
                                    <Typography>{item.label}</Typography>
                                    {/* <Box sx={{ backgroundColor: "red" }}>{item.label}</Box> */}
                                </Grid>
                                <Grid item xs={item.field === "documentTransmittalNumber" || item.field === "to" ? 2.3 : 2}>
                                    <TextField
                                        id="outlined-basic"
                                        variant="outlined"
                                        hiddenLabel
                                        size="small"
                                        disabled
                                        fullWidth
                                        inputProps={{ sx: { padding: "2px", pl: "5px" } }}
                                    />
                                </Grid>
                            </React.Fragment>
                        ))}
                    </Grid>
                </Box>
                <br />
                <MaterialReactTable columns={documentTransmittalColumns} data={[]} {...lightTable} />
            </Box>
        </div >

    )
}

export default DocumentTransmittalTab
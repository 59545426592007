import * as msal from "@azure/msal-browser";

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_AD_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_AD_TENANT_ID}`,
    redirectUri: "/dev/login",
  },
};

// console.log(msalConfig);
const msalInstance = new msal.PublicClientApplication(msalConfig);

export { msalInstance };

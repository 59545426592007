import {
    Badge,
    Box,
    Button,
    Chip,
    IconButton,
    MenuItem,
    Typography,
} from "@mui/material";
import ChatIcon from "@mui/icons-material/Chat";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import PostAddIcon from "@mui/icons-material/PostAdd";
import { MRT_ColumnDef } from "material-react-table";
// import { CircularProgressWithLabel } from "../../../../../components/widgets/CircularProgressWithLabel";

const getOrdinalSuffix = (number) => {
    const suffixes = ["th", "st", "nd", "rd"];
    const v = number % 100;
    return number + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
};

export const convertDateString = (dateString) => {
    const dateObject = new Date(dateString);
    // Format the date
    const formattedDate = `${getOrdinalSuffix(dateObject.getDate())} ${dateObject.toLocaleString('en-US', { month: 'short' })} ${dateObject.getFullYear()}`;
    return (
        <p>{formattedDate}</p>
    );
};

const statusColorMap = {
    'Not Started': 'default',
    'Progress': 'primary',
    'Hold': 'warning',
    'Completed': 'success',
    'Delayed': 'error',
};

export const initialDialogState = {
    comments: false,
    attachment: false,
    timesheet: false
}

export const taskListColumns = ({
    project_members,
    handleButtonClick,
    currentUser,
}) => [
        {
            accessorKey: "id",
            header: `ID`,
            enableEditing: false,
            Cell: ({ cell, row }) => (
                <>
                </>
            )
        },
        {
            accessorKey: "task",
            header: `Task`,
            enableEditing: (row) => {
                if (row.original.task === "") return true;
                else if (currentUser)
                    return (row?.original?.task_members?.includes(currentUser))
                else return false;
            },
            size: 320,
        },
        {
            accessorKey: "users",
            header: `Responsible`,
            size: 100,
            enableSorting: false,
            Cell: ({ cell, row }) => {
                console.log("user value", cell.getValue())
                return (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {
                            cell.getValue() ?
                                cell.getValue().map((user, index) => (
                                    <Chip key={index} label={user} size="small" />
                                ))
                                :
                                ""
                        }
                    </Box>
                )
            },
            muiEditTextFieldProps: ({ row }) => ({
                select: true,
                onChange: (event) => {
                    const selectedUser = event.target.value;

                    row.original.users = [];

                    // To avoid duplicates, you might want to check if the user already exists
                    // if (!row.original.users.includes(selectedUser)) {
                    row.original.users.push(selectedUser);
                    // }

                },
                children: project_members?.map((user, index) => (
                    <MenuItem key={index} value={user.name}>
                        {user.name}
                    </MenuItem>
                )),
                SelectProps: {
                    multiple: true
                },
            })
        },
        // {
        //     accessorKey: "users",
        //     header: `Responsible`,
        //     size: 100,
        //     Cell: ({ cell }) => {
        //         return (
        //             <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
        //                 {cell.getValue() ?
        //                     cell.getValue().map((user, index) => (
        //                         // <Chip key={index} label={project_members?.find(member => member.userId === userId)?.name} />
        //                         <Chip key={index} label={user?.name || "unknown"} />
        //                     )) :
        //                     ""
        //                 }
        //             </Box>
        //         );
        //     },
        //     muiEditTextFieldProps: ({ value, onChange }) => ({
        //         children: project_members?.map((user, index) => (
        //             <MenuItem key={index} value={user.name}>
        //                 {user.name}
        //             </MenuItem>
        //         )),
        //         select: true,
        //         SelectProps: {
        //             multiple: true,
        //             renderValue: (selected) => (
        //                 <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
        //                     {selected.map((value, index) => {
        //                         console.log({ value })
        //                         return (
        //                             <Chip key={index} label={value} />
        //                         )
        //                     })}
        //                 </Box>
        //             )
        //         }
        //     })
        //     // muiEditTextFieldProps: ({ value, onChange }) => ({
        //     //     children: (project_members || []).map((user) => (
        //     //         <MenuItem key={user.id} value={user.id}>
        //     //             {user.name}
        //     //         </MenuItem>
        //     //     )),
        //     //     select: true,
        //     //     SelectProps: {
        //     //         multiple: true,
        //     //         renderValue: (selected) => (
        //     //             <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
        //     //                 {selected.map((value, index) => (
        //     //                     // <Chip key={index} label={project_members?.find(member => member.id === value)?.name} />
        //     //                     <Chip key={index} label={project_members?.find(member => member.id === value)?.name} />
        //     //                 ))}
        //     //             </Box>
        //     //         ),
        //     // onChange: (event) => {
        //     //     onChange(event.target.value);
        //     // }
        //     //     }
        //     // })
        // },
        {
            accessorKey: "priority",
            header: 'Priority',
            size: 90,
            muiEditTextFieldProps: () => ({
                children: ["High", "Medium", "Low"].map((func) => (
                    <MenuItem key={func} value={func}>
                        {func}
                    </MenuItem>
                )),
                select: true,
            }),
        },
        {
            accessorKey: "endDate",
            header: `Due`,
            size: 100,
            enableColumnFilter: false,
            Cell: ({ cell }) => (
                <>
                    {
                        cell.getValue() ?
                            convertDateString(cell.getValue())
                            : ""
                    }
                </>
            ),
            muiEditTextFieldProps: ({ cell }) => ({
                type: "date",
            }),
        },
        {
            accessorKey: "startDate",
            header: `start`,
            size: 100,
            enableColumnFilter: false,
            Cell: ({ cell }) => (
                <>
                    {
                        cell.getValue() ?
                            convertDateString(cell.getValue())
                            : ""
                    }
                </>
            ),
            muiEditTextFieldProps: ({ cell }) => ({
                type: "date",
            }),
        },
        {
            accessorKey: "timesheets",
            header: 'Hours',
            enableEditing: false,
            enableColumnFilter: false,
            Cell: ({ cell }) => {
                let totalHours = 0;
                cell.getValue()?.forEach((entry) => {
                    totalHours += parseFloat(entry.hours); // Convert hours to float and add to total
                });
                return (<div>
                    {totalHours}
                </div>)
            },
            size: 80,
        },
        {
            accessorKey: "timesheets.progress",
            header: 'Progress',
            enableEditing: false,
            enableColumnFilter: false,
            size: 80,

            Cell: ({ cell, row }) => {
                let totalProgress = 0;
                console.log(row.original.timesheets)
                row.original?.timesheets?.forEach((entry) => {
                    totalProgress += parseFloat(entry.progress); // Convert hours to float and add to total
                });
                return (<div>
                    {totalProgress}
                </div>)
            },
        },
        {
            accessorKey: "status",
            header: `Status`,
            size: 90,
            muiEditTextFieldProps: () => ({
                children: ["Not Started", "Progress", "Hold", "Completed", "Delayed"].map((func) => (
                    <MenuItem key={func} value={func}>
                        {func}
                    </MenuItem>
                )),
                select: true,
            }),
            Cell: ({ cell }) => (
                <>
                    {
                        cell.getValue() ?
                            <Chip
                                label={cell.getValue()}
                                color={statusColorMap[cell.getValue()]}
                            /> :
                            <></>
                    }
                </>
            ),
        },
        {
            accessorKey: "comments",
            header: `Comments`,
            enableEditing: false,
            enableSorting: false,
            enableColumnFilter: false,
            size: 50,
            Header: () => <ChatIcon />,
            Cell: ({ row }) => (
                <IconButton
                    onClick={() => handleButtonClick('comments', row)}
                // disabled={project_members?.includes(currentUser) || projectLeads.includes(currentUser) ? false : true}
                >
                    {/* <Badge badgeContent={2} color="primary" > */}
                    <ChatIcon />
                    {/* </Badge> */}
                </IconButton>
            ),
        },
        {
            accessorKey: "attachment",
            header: `Attachment`,
            enableEditing: false,
            enableSorting: false,
            enableColumnFilter: false,
            size: 20,
            Header: () => <AttachFileIcon />,
            Cell: ({ row }) => (
                <IconButton
                    onClick={() => handleButtonClick('attachment', row)}
                >
                    <AttachFileIcon />
                </IconButton>
            ),
        },
        {
            accessorKey: "timesheet",
            header: `Timesheet`,
            enableEditing: false,
            enableSorting: false,
            enableColumnFilter: false,
            size: 20,
            Header: () => <PostAddIcon />,
            Cell: ({ row }) => (
                <IconButton
                    onClick={() => handleButtonClick('timesheet', row)}
                >
                    <PostAddIcon />
                </IconButton>
            ),
        },
    ];

import { useState } from "react";
import { Button, Card, CardContent, Stack, Typography } from "@mui/material";
import DocumentList from "./DocumentList";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import DocumentTransmittalModal from "./DocTransmittalMadal";
import { useDCS } from "../DCSContext";

export default function DocumentListCard({ type }) {
  const [docListState, setDocListState] = useState(false);
  const [docTransState, setDocTransState] = useState(false);
  const { documentList, getDocList } = useDCS();
  console.log(type);

  const handleDocumentListClose = () => {
    getDocList();
    setDocListState(false);
  };
  const handleDocTransClose = () => {
    setDocTransState(false);
  };
  return (
    <>
      <Card key={5} sx={{ width: "288px" }}>
        <CardContent>
          <Typography fontSize={"12px"} fontWeight={600} component="div">
            {type} List
          </Typography>
          <Stack
            direction="row"
            paddingTop={1}
            sx={{ justifyContent: "space-between" }}
          >
            <Typography variant="h5">{documentList.length}</Typography>
            <Button
              sx={{
                backgroundColor: "#28E8F6",
                cursor: "default",
                cursorEvents: "none",
                "&:hover": {
                  backgroundColor: "#28E8F6",
                  cursor: "pointer",
                },
              }}
              onClick={() => {
                setDocListState(true);
              }}
            >
              <FileCopyIcon sx={{ color: "#5D4108FF" }} />
            </Button>
          </Stack>
        </CardContent>
      </Card>
      <DocumentList
        open={docListState}
        handleClose={handleDocumentListClose}
        type={type}
      />
      <Card key={7} sx={{ width: "288px" }}>
        <CardContent>
          <Typography fontSize={"12px"} fontWeight={600} component="div">
            Documents Transimittal
          </Typography>
          <Stack
            direction="row"
            paddingTop={1}
            sx={{ justifyContent: "space-between" }}
          >
            <Typography variant="h5">0</Typography>
            <Button
              sx={{
                backgroundColor: "#EFC3CA",
                cursor: "default",
                cursorEvents: "none",
                "&:hover": {
                  backgroundColor: "#EFC3CA ",
                  cursor: "pointer",
                },
              }}
              onClick={() => {
                setDocTransState(true);
              }}
            >
              <MoveUpIcon sx={{ color: "#5D4108FF" }} />
            </Button>
          </Stack>
        </CardContent>
      </Card>
      <DocumentTransmittalModal
        open={docTransState}
        handleClose={handleDocTransClose}
        type={type}
      />
    </>
  );
}

// import project from './interfaces/projectInterface';
// import axois from '../../common/api/axois';
// import axios from 'axios';

import { secondAxios } from "../../../../../../api/axios";


export const getAllTasks = async () => {
    const response = await secondAxios.get(`/task`);
    return response.data;
};

export const getTasks = async (projectId) => {
    const response = await secondAxios.get(`/task?projectId=${projectId}`);
    return response.data;
};

export const createTask = async (parentId, task) => {
    console.log(task)
    // TODO: Add parentId to task details here.
    await secondAxios.post('/task', task);
};

export const updateTask = async (task) => {
    await secondAxios.patch(`/task/${task.id}`, task);
};

export const deleteTask = async (taskId) => {
    await secondAxios.delete(`/task/${taskId}`);
};


export const getAllUsers = async () => {
    const res = await secondAxios.get("/user")
    return res.data
}
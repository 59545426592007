import { Box, Button } from "@mui/material";
import { MaterialReactTable } from "material-react-table";
import React from "react";

const RowEditableTable = ({
  columns,
  data,
  handleSaveRow,
  handleCreateRow,
  additionalProps,
  topForm,
}) => {
  return (
    <Box
      sx={{ "& td": { p: 1, borderRight: "1px solid #ddd", overflow: "auto" } }}
    >
      <MaterialReactTable
        columns={columns}
        data={data}
        editDisplayMode="row"
        enableEditing
        // onEditingRowSave={(exitEditingMode, row, values) => handleSaveRow(exitEditingMode, row, values)}
        onEditingRowSave={handleSaveRow}
        positionActionsColumn="last"
        muiTableHeadCellProps={({ cell }) => ({
          sx: {
            borderRight: "1px solid rgba(224,224,224,1)",
            backgroundColor: "hsla(250, 86%, 97%, 1)",
            pt: 2,
          },
        })}
        defaultColumn={{
          minSize: 20, //allow columns to get smaller than default
          maxSize: 900, //allow columns to get larger than default
        }}
        renderTopToolbarCustomActions={() => (topForm ? topForm : <></>)}
        renderBottomToolbarCustomActions={() => (
          <>
            <Button
              color="secondary"
              size="small"
              onClick={() => handleCreateRow()}
              variant="contained"
            >
              Add row
            </Button>
          </>
        )}
        {...additionalProps}
      />
    </Box>
  );
};

export default RowEditableTable;

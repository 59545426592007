import React, { useEffect } from "react";
import TabPanel from "../../../../components/widgets/TabPanel";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

//Drawer imports
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";

import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";

import SaveIcon from "@mui/icons-material/Save";
import HomeIcon from "@mui/icons-material/Home";
// Drawer Header
import { styled } from "@mui/material/styles";

// import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

// Form provider
import { useForm, FormProvider } from "react-hook-form";
// import axios, { secondAxios } from "../../../../api/axios";
import { Alert, Snackbar, Typography, createTheme } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
// import TankData from "./Tank_Tab_Data/TankData";
// import TankCalculation from "./TankCalculation";
import { theme } from "../../../../styles/theme";
import { tabProps, tabScrollerProps } from "../../../../styles/muiProps";
import EstimationTab from "./Tank_Estimation/Tank_Estimation";
// import MCSPage from "../CommonFeatures/MCSTab";
// import PCS from "../CommonFeatures/PCSTab";
import { useParams } from "react-router-dom";
import { CommonTabs, equipMainTabs } from "../CommonFeatures/utils";
import { getProjectById } from "./api";
import { getHashdata } from "../PressureVessel/utils";

// const DrawerIcons = [
//   <HomeIcon />,
//   <CreateNewFolderIcon />,
//   <FileOpenIcon />,
//   <SaveIcon />,
// ];

// const DrawerHeader = styled("div")(({ theme }) => ({
//   display: "flex",
//   alignItems: "center",
//   padding: theme.spacing(0, 1),
//   // necessary for content to be below app bar
//   ...theme.mixins.toolbar,
//   justifyContent: "flex-end",
//   backgroundColor: "#eaecee",
// }));

const navLabel = "main-nav";

const getCurrentTab = () => {
  const hashValue = getHashdata(window.location.hash, navLabel);
  // console.log({ hashValue })
  const tabIndex = equipMainTabs.findIndex((tab) => tab === hashValue);
  console.log({ tabIndex });
  if (tabIndex !== -1) {
    return tabIndex;
  } else {
    window.location.hash = navLabel + "=" + equipMainTabs[1];
    return 1;
  }
};

const TankMain = () => {
  // const [openDrawer, setOpenDrawer] = React.useState(false); //file menu bar
  // const [open, setOpen] = React.useState(false);
  const [currentTab, setCurrentTab] = React.useState(getCurrentTab() || 0); //value of the current tab
  const [key, setKey] = React.useState(Math.random());
  const { id: projectId, equipId } = useParams();
  //form handler
  const methods = useForm();

  const [project, setProject] = React.useState({});

  //opening the file menu
  // const toggleDrawer = (state) => {
  //   setOpenDrawer(state);
  // };

  //handles changes in tab
  const handleTabChange = (event, newValue) => {
    // if (newValue === 0) {
    //   toggleDrawer(true);
    //   return;
    // }
    setCurrentTab(newValue);
    window.location.hash = navLabel + "=" + equipMainTabs[newValue];
  };

  const reRender = () => {
    setKey(Math.random());
  };

  // get current tab index from hash value

  // const onSave = async (data) => {
  // saving the data to the txt file
  // const onSave = async () => {
  //   const data = methods.getValues();
  //   console.log("data", data);
  //   // event.preventDefault()
  //   const result = await axios
  //     .post("/tank/create", data)
  //     .then((response) => {
  //       setOpen(true);
  //       console.log(response);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  //restore all values
  // const getPreviousValues = async () => {
  //   let prevData = 0;
  //   const read = await axios
  //     .get("/tank/getFile")
  //     .then((response) => {
  //       console.log("retrieved:", response.data);
  //       prevData = response.data.data;
  //       methods.reset(prevData);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  //closing the file menu
  // const handleClose = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }
  //   setOpen(false);
  // };

  useEffect(() => {
    setCurrentTab(getCurrentTab());

    // console.log(projectId);

    const getProjectFile = async () => {
      // console.log("projectId", projectId);

      const res = await getProjectById({
        projectId: equipId,
      });
      // console.log("projectssssss", res);

      methods.reset(res);
      setProject(res);
    };
    getProjectFile();
  }, []);

  //File menu list
  // const list = () => (
  //   <Box
  //     sx={{ width: 200 }}
  //     role="presentation"
  //     onClick={() => setOpenDrawer(false)}
  //     onKeyDown={() => setOpenDrawer(false)}
  //   >
  //     <List>
  //       {["Home", "New Project", "Open Project", "Save"].map((text, index) => (
  //         <ListItem key={text} disablePadding>
  //           <ListItemButton
  //             onClick={[, , () => getPreviousValues(), () => onSave()][index]}
  //           >
  //             <ListItemIcon>{DrawerIcons[index]}</ListItemIcon>
  //             <ListItemText primary={text} />
  //           </ListItemButton>
  //         </ListItem>
  //       ))}
  //     </List>
  //   </Box>
  // );

  return (
    <ThemeProvider theme={theme}>
      <FormProvider {...methods}>
        {" "}
        {/*  pass all methods into the context */}
        <Box sx={{ width: "100%" }}>
          <Box {...tabScrollerProps}>
            {/* <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
              <Alert
                onClose={handleClose}
                severity="success"
                sx={{ width: "100%" }}
              >
                File created successfully!
              </Alert>
            </Snackbar> */}
            <Tabs value={currentTab} onChange={handleTabChange} {...tabProps}>
              {equipMainTabs.map((tabName) => {
                return <Tab key={tabName} label={tabName} />;
              })}
              <Typography variant="h6" color={"secondary"} m={"auto 10px"}>
                Tank
              </Typography>
            </Tabs>
            {/* <Drawer
              anchor="left"
              open={openDrawer}
              onClose={() => setOpenDrawer(false)}
            >
              <DrawerHeader>
                <Button
                  variant=""
                  startIcon={<ChevronLeftIcon />}
                  onClick={() => setOpenDrawer(false)}
                >
                  Back
                </Button>
              </DrawerHeader>
              {list()}
            </Drawer> */}
            {/* <TabPanel
              backgroundColor="transparent"
              value={currentTab}
              index={1}
              padding={0}
            >
              <TankData />
            </TabPanel>
            <TabPanel value={currentTab} index={2} padding={1}>
              <TankCalculation />
            </TabPanel> */}
            {CommonTabs(reRender, project).map((tab, index) => {
              if (tab.title === "EstimationTab") {
                return (
                  <TabPanel
                    value={currentTab}
                    index={index}
                    padding={tab.panelProps?.padding ?? 0}
                  >
                    <EstimationTab />
                  </TabPanel>
                );
              }
              return (
                <TabPanel
                  value={currentTab}
                  index={index}
                  padding={tab.panelProps?.padding ?? 0}
                >
                  {tab.panel}
                </TabPanel>
              );
            })}
          </Box>
        </Box>
      </FormProvider>
    </ThemeProvider>
  );
};

export default TankMain;

import { Box, Stack } from "@mui/material";

const EstimationColumns = () => [
  {
    accessorKey: "Material Code",
    header: "Material Code",
    size: 120,
    enableSorting: false,
    // muiTableHeadCellProps: { align: "center" },
  },
  {
    accessorKey: "Material Description",
    header: "Material Description",
    size: 220,
    enableHiding: false,
    enableSorting: false,
  },
  // {
  //   accessorKey: "Actual Weight",
  //   header: "Actual Weight",
  //   size: 50,
  //   enableSorting: false,
  //   muiTableBodyCellProps: { align: "right" },
  //   // muiTableHeadCellProps: { align: "center" },
  // },
  {
    accessorKey: "Estimated Weight",
    header: "Estimated Weight",
    size: 50,
    enableSorting: false,
    muiTableBodyCellProps: { align: "right" },
    // muiTableHeadCellProps: { align: "center" },
  },
  {
    accessorKey: "Plate to be procured",
    header: "Plate to be procured",
    size: 200,
    enableSorting: false,
    // muiTableHeadCellProps: { align: "center" },
  },
  {
    accessorKey: "Qty",
    header: "Qty",
    size: 50,
    enableSorting: false,
    muiTableBodyCellProps: { align: "right" },
  },
  {
    accessorKey: "Unit",
    header: "Unit",
    size: 70,
    enableSorting: false,
    // muiTableHeadCellProps: { align: "center" },
  },
  {
    accessorKey: "Unit Cost",
    header: "Unit Cost",
    size: 70,
    enableSorting: false,
    muiTableBodyCellProps: { align: "right" },
    // muiTableHeadCellProps: { align: "center" },
  },
  {
    accessorKey: "Total Cost",
    header: "Total Cost",
    enableSorting: false,
    muiTableBodyCellProps: { align: "right" },
    // muiTableHeadCellProps: { align: "center" },
    size: "50",
  },
  {
    accessorKey: "Manhours",
    header: "Manhours",
    size: 50,
    enableSorting: false,
    muiTableBodyCellProps: { align: "right" },
    Cell: ({ cell }) => (
      <>{cell.getValue() ? parseFloat(cell.getValue()).toFixed(2) : ""}</>
    ),
    // muiTableHeadCellProps: { align: "center" },
  },
  {
    accessorKey: "Remarks",
    header: "Remarks",
    enableSorting: false,
    // muiTableHeadCellProps: { align: "center" },
    // size: "40px"
  },
];

export const SummaryColumns = () => {
  return [
    {
      accessorKey: "Summary",
      header: "Summary",
      size: 150,
      enableSorting: false,
      muiTableHeadCellProps: { align: "center" },
    },
    {
      accessorKey: `estimated`,
      header: "Est.",
      size: 100,
      enableSorting: false,
      muiTableBodyCellProps: { align: "right" },
      muiTableHeadCellProps: { align: "center" },
    },
    {
      accessorKey: "% Added",
      header: "% Added",
      size: 50,
      enableSorting: false,
      muiTableBodyCellProps: { align: "right" },
      muiTableHeadCellProps: { align: "center" },
    },
    {
      accessorKey: "Total",
      header: "Total",
      size: 100,
      enableSorting: false,
      muiTableBodyCellProps: { align: "right" },
      muiTableHeadCellProps: { align: "center" },
    },
  ];
};

export const NozzleEstimationColumns = () => {
  return [
    {
      accessorKey: "Mark",
      header: "Mark",
      size: 30,
      enableSorting: false,
      muiTableHeadCellProps: { align: "center" },
    },
    {
      accessorKey: "Flange_Weight",
      header: "Flange Weight (Kg).",
      size: 30,
      enableSorting: false,
      muiTableHeadCellProps: { align: "center" },
    },
    {
      accessorKey: "Pipe_Weight",
      header: "Pipe Weight (Kg)",
      size: 30,
      enableSorting: false,
      muiTableHeadCellProps: { align: "center" },
    },
    {
      accessorKey: "Pad_Weight",
      header: "Pad Weight (Kg)",
      size: 30,
      enableSorting: false,
      muiTableHeadCellProps: { align: "center" },
    },
    {
      accessorKey: "Total_Wieght",
      header: "Total Weight (Kg)",
      size: 30,
      enableSorting: false,
      muiTableHeadCellProps: { align: "center" },
    },
    {
      accessorKey: "Model_Wieght",
      header: "3D Model Weight (Kg)",
      size: 30,
      enableSorting: false,
      muiTableHeadCellProps: { align: "center" },
    },
    {
      accessorKey: "Weld_Manhour",
      header: "Weld Manhour",
      size: 30,
      enableSorting: false,
      muiTableHeadCellProps: { align: "center" },
    },
    {
      accessorKey: "Bolt_Manhour",
      header: "Bolt Manhour",
      size: 30,
      enableSorting: false,
      muiTableHeadCellProps: { align: "center" },
    },
    {
      accessorKey: "NDT_Cost",
      header: "NDT Cost ($)",
      size: 30,
      enableSorting: false,
      muiTableHeadCellProps: { align: "center" },
    },
  ];
};

export const AddToFabricationColumns = [
  {
    accessorKey: "Material Code",
    header: "Material Code",
    size: 120,
    enableSorting: false,
    // muiTableHeadCellProps: { align: "center" },
  },
  {
    accessorKey: "Material Description",
    header: "Material Description",
    size: 220,
    enableHiding: false,
    enableSorting: false,
  },
  {
    accessorKey: "Manhours",
    header: "Manhours",
    size: 50,
    enableSorting: false,
    muiTableBodyCellProps: { align: "right" },
    Cell: ({ cell }) => (
      <>{cell.getValue() ? parseFloat(cell.getValue()).toFixed(2) : ""}</>
    ),
    // muiTableHeadCellProps: { align: "center" },
  },
];

export default EstimationColumns;

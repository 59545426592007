export const DocumentTransmittalGrid = [
    {
        label: "Document Transmittal Number:",
        field: "documentTransmittalNumber",
        grid: 1.6,
    },
    {
        label: "Date:",
        field: "date",
        grid: 0.6,
    },
    {
        label: "To:",
        field: "to",
        grid: 1.6,
    },
    {
        label: "Email ID:",
        field: "email",
        grid: 0.6,
    }
]

export const documentTransmittalColumns = [
    {
        accessorKey: "sent",
        header: "Sent Information",
        columns: [
            {
                accessorKey: "date",
                header: "Date",
                enableEditing: false,
                // grow: false, //don't allow this column to grow to fill in remaining space
                size: 80, //small column
            },
            {
                accessorKey: "documentNumber",
                header: "Doc. Number",
                enableEditing: false,
                // grow: false, //don't allow this column to grow to fill in remaining space
                size: 120, //small column
            },
            {
                accessorKey: "description",
                header: "Description",
                grow: false, //don't allow this column to grow to fill in remaining space
                size: 100, //small column
            },
            {
                accessorKey: "revNumber",
                header: "Revision #",
                grow: false, //don't allow this column to grow to fill in remaining space
                size: 100, //small column
            },
            {
                accessorKey: "uploadDocs",
                header: "Upload Docs",
                grow: false, //don't allow this column to grow to fill in remaining space
                size: 100, //small column
            },
        ]
    },
    {
        accessorKey: "received",
        header: "Received Information",
        columns: [
            {
                accessorKey: "receivedDate",
                header: "Received Date",
                grow: false, //don't allow this column to grow to fill in remaining space
                size: 100, //small column
            },
            {
                accessorKey: "uploadDocsR",
                header: "Upload Documents",
                grow: false, //don't allow this column to grow to fill in remaining space
                size: 100, //small column
            },
        ]
    }
];

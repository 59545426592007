import * as React from "react";
import { Box } from "@mui/material";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { lightTable } from "../../../../../../../styles/muiProps";
import { SubMaterialsTableCols } from "./col";
import { data } from "./dummy";

export default function SubMaterialTable({}) {
  const table = useMaterialReactTable({
    data,
    columns: SubMaterialsTableCols,
    ...lightTable,
    getRowId: (originalRow) => originalRow.id,

    enableStickyHeader: true,
    enableStickyFooter: true,
    muiTableBodyCellProps: {
      sx: {
        textAlign: "center",
        border: "1px solid rgba(81, 81, 81, .1)",
        padding: "10px",
      },
    },
    muiTableFooterCellProps: {
      sx: {
        border: "0.5px solid rgba(81, 81, 81, .1)",
        // fontStyle: 'italic',
        fontWeight: "normal",
        padding: "10px",
        backgroundColor: "#f3f1fe",
      },
    },
  });

  return (
    <Box sx={{ backgroundColor: "#fff" }}>
      <MaterialReactTable table={table} />
    </Box>
  );
}

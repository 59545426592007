import React, { useEffect, useState } from "react";
import {
  Modal,
  TextField,
  Button,
  Box,
  MenuItem,
  Typography,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import { UploadFile as UploadFileIcon } from "@mui/icons-material";
import { useDCS } from "./DCSContext";
import { secondAxios } from "../../../../../../api/axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const NewRowModal = ({ open, handleClose, handleAddRow, allUsers, type }) => {
  const { documentList } = useDCS();
  const [formData, setFormData] = useState({
    document: "",
    revNum: "",
    docNum: "",
    docLink: "",
    progress: 0,
    responsible: "",
    accountables: "",
    consulted: "",
    informed: "",
    mail: "",
    chat: "",
    timesheet: "",
    file: null,
  });
  const [isUploading, setIsUploading] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(""); // Snackbar message
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // Snackbar severity (success or error)
  const [openSnackbar, setOpenSnackbar] = useState(false); // Control Snackbar visibility

  useEffect(() => {
    setFormData({
      document: "",
      revNum: "",
      docNum: "",
      docLink: "",
      progress: 0,
      responsible: "",
      accountables: "",
      consulted: "",
      informed: "",
      mail: "",
      chat: "",
      timesheet: "",
      file: null,
    });
    setFileUploaded(false);
    setSnackbarMessage("");
    setOpenSnackbar(false);
  }, [open]);

  // File upload to SharePoint
  const uploadFileToSharePoint = async (file) => {
    console.log("fileeee", file);

    setIsUploading(true);
    setSnackbarMessage("");
    try {
      const res = await secondAxios.post(
        `/sharepoint/upload/${type}`,
        { file },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("Upload response", res);
      setFormData((prev) => ({
        ...prev,
        docLink: `https://enerfacprojectscom.sharepoint.com/sites/StorageTank/Shared%20Documents/EP - ST - 001/DCS/${type}/${file.name}`,
      }));
      setFileUploaded(true);
      setSnackbarMessage("File uploaded successfully!");
      setSnackbarSeverity("success");
    } catch (error) {
      console.error("Error during file upload:", error);
      setSnackbarMessage("Error uploading file. Please try again.");
      setSnackbarSeverity("error");
    } finally {
      setIsUploading(false);
      setOpenSnackbar(true); // Show Snackbar after file upload
    }
  };

  // Handle file selection and upload
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === "application/pdf") {
      setFormData((prev) => ({
        ...prev,
        file: file,
      }));
      uploadFileToSharePoint(file);
    } else {
      setSnackbarMessage("Please upload a valid PDF file.");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    }
  };

  // Handle document selection and update document number
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "document") {
      const selectedDocument = documentList.find((doc) => doc.title === value);
      console.log("doccccc", selectedDocument);

      setFormData((prev) => ({
        ...prev,
        [name]: value, // Update document field
        docNum: selectedDocument ? selectedDocument.docNumber : "", // Set docNum based on selected document
      }));
      console.log("dataaaaa", formData);
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  // Handle form submission
  const handleSubmit = () => {
    if (!fileUploaded) {
      setSnackbarMessage("You must upload a file before submitting.");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      return;
    }

    handleAddRow(formData); // Send the data back to parent component
    setSnackbarMessage("document added successfully!");
    setSnackbarSeverity("success");
    setOpenSnackbar(true);
    setTimeout(() => {
      handleClose();
    }, 2000);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Typography variant="h6" gutterBottom>
          Add New Document
        </Typography>

        {/* Document */}
        <TextField
          fullWidth
          select
          label="Document"
          name="document"
          value={formData.document}
          onChange={handleChange}
          margin="normal"
        >
          {documentList.map((d) => (
            <MenuItem key={d.title} value={d.title}>
              {d.title}
            </MenuItem>
          ))}
        </TextField>

        {/* Document Number (Auto-updated based on document selection) */}
        <TextField
          fullWidth
          label="Document #"
          name="docNum"
          value={formData.docNum}
          onChange={handleChange}
          margin="normal"
          disabled
        />

        {/* RevNum */}
        <TextField
          fullWidth
          label="Rev #"
          name="revNum"
          value={formData.revNum}
          onChange={handleChange}
          margin="normal"
        />

        {/* Doc Link */}
        <TextField
          fullWidth
          label="Doc Link"
          name="docLink"
          value={formData.docLink}
          onChange={handleChange}
          margin="normal"
        />

        {/* Progress */}
        <TextField
          fullWidth
          label="Progress"
          name="progress"
          type="number"
          value={formData.progress}
          onChange={handleChange}
          margin="normal"
        />

        {/* Responsible (Dropdown with all users) */}
        <TextField
          fullWidth
          select
          label="Responsible"
          name="responsible"
          value={formData.responsible}
          onChange={handleChange}
          margin="normal"
        >
          {allUsers.map((user) => (
            <MenuItem key={user.email} value={user.email}>
              {user.email}
            </MenuItem>
          ))}
        </TextField>

        {/* Upload PDF Button */}
        <Box sx={{ mt: 2 }}>
          <input
            accept="application/pdf"
            style={{ display: "none" }}
            id="upload-pdf"
            type="file"
            onChange={handleFileChange}
          />
          <label htmlFor="upload-pdf">
            <Button
              variant="contained"
              component="span"
              startIcon={<UploadFileIcon />}
            >
              Upload PDF
            </Button>
          </label>
          {formData.file && (
            <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
              {`Uploaded File: ${formData.file.name}`}
            </Typography>
          )}
        </Box>

        {isUploading && <CircularProgress size={24} sx={{ mt: 2 }} />}

        {/* Submit Button */}
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          sx={{ mt: 2 }}
          disabled={!fileUploaded || isUploading}
        >
          Add Row
        </Button>

        {/* Snackbar for messages */}
        <Snackbar
          open={openSnackbar}
          autoHideDuration={1000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity={snackbarSeverity}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Modal>
  );
};

export default NewRowModal;
